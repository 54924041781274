import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./ProfileCompletion.css";
import { useHistory } from "react-router";

import { ReactComponent as SVGDefault } from "../../elements/svg/default-profile.svg";
import { useState, useEffect } from "react";

const ProfileCompletion = ({ expertData }) => {
  const history = useHistory();
  const [percentage, setPercentage] = useState(55);
  useEffect(() => {
    if (expertData) {
      let newPercent = 55;
      if (expertData.rmp_number) {
        newPercent = newPercent + 10;
      }
      if (expertData.clinic_pincode) {
        newPercent = newPercent + 5;
      }
      if (expertData.clinic_address) {
        newPercent = newPercent + 5;
      }
      if (expertData.clinic_name) {
        newPercent = newPercent + 5;
      }
      if (expertData.profile_picture) {
        newPercent = newPercent + 10;
      }
      if (expertData.degree_details && expertData.degree_details.length > 0) {
        newPercent = newPercent + 10;
        let isCert = false;
        expertData.degree_details.map((ele) => {
          if (ele.certificate_url) {
            isCert = true;
          }
        });
        if (isCert) {
          newPercent = newPercent + 10;
        }
      }
      if (newPercent > 100) {
        newPercent = 100;
      }
      setPercentage(newPercent);
    }
  }, [expertData]);
  return (
    <>
      <div className="wrapper" onClick={() => history.push("/profile")}>
        <div className="wrapper-p1">
          <div className="progressbar">
            <CircularProgressbarWithChildren
              value={percentage}
              styles={{
                // Customize the circle behind the path, i.e. the "total progress"
                path: {
                  // Path color
                  stroke: "#DC4A9A",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Customize transition animation
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  // Rotate the path
                  // transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },

                trail: {
                  // Trail color
                  stroke: "#DC4A9A",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",
                  // Rotate the trail
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the text
                // text: {
                //   // Text color
                //   fill: "#f88",
                //   // Text size
                //   fontSize: "16px",
                // },
                // Customize background - only used when the `background` prop is true
                background: {
                  fill: "#fff",
                },
              }}
              background={true}
            >
              {expertData.profile_picture ? (
                <img
                  className="profile_completion_comp-img"
                  src={expertData.profile_picture}
                  alt="Dr_image"
                />
              ) : (
                <div className="profile_completion_comp-default_img">
                  <SVGDefault />
                </div>

              )}
              <div style={{ fontSize: 12, marginTop: -5 }}></div>
            </CircularProgressbarWithChildren>
          </div>
          <div style={{ position: "relative", display: "inline" }}>
            <div className="completionTag">
              <div className="profile_completion_comp-lable-perc">{percentage}%</div>
            </div>
          </div>


        </div>
        <div className="wrapper-p2">
          <div className="wrapper-p2-doc">
          {"Dr. " + expertData.name}
          </div>
          <div
            className="profile_completion_comp-lable margin-top"
            style={{ color: "#DC4A9A",textDecoration:'underline',display:"flex",flexDirection:"row" }}
          >
            Edit your profile<span className="right-arrow"><img src="/images/right-arrow.svg" alt="edit" /></span>
            
          </div>
        </div>

      </div>
    </>
  );
};

export default ProfileCompletion;
