import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect } from 'react';
import Navbar from '../../components/common/navbar/Navbar';
import HeaderWithProfile from "../../components/headerWithProfile";
import './successfulReferral.css';
import { EditOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Heading2, Heading3, Heading4, Heading5 } from "../../components/elements/text";
import { getSuccessfulReferral } from '../../stores/actions/successfulReferral';
import { successfulReferralListSelector } from '../../stores/selectors/successfulReferral';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


const SuccessfulReferral = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSuccessfulReferral());
    }, [dispatch]);

    const successfulReferralList = useSelector((state) => successfulReferralListSelector(state));
    console.log(successfulReferralList);

    const referralAmount = successfulReferralList.length ? successfulReferralList.reduce((acc, curr) => acc + curr.amount, 0) : 0;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            responsive: ['lg'],
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            responsive: ['lg'],
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Status',
            dataIndex: '',
            key: 'x',
            responsive: ['lg'],
            render: () => <span><CheckCircleOutlined style={{ color: "#0BBE78", position: "absolute", bottom: "36.36%", marginLeft: "5px" }} /></span>,
        },
        {
            title: 'Order ID',
            dataIndex: 'orderId',
            key: 'orderId',
            responsive: ['lg'],
        },
    ];
    const data = successfulReferralList.length ? successfulReferralList.map((item, index) => ({
        key: index,
        date: item.created_at ? moment(item.created_at).format("MMM Do YYYY") : "N/A",
        name: item.name ? item.name : "N/A",
        age: item.age ? item.age : "N/A",
        phoneNumber: item.callingNumber ?  item.callingNumber : "N/A",
        amount: item.amount ? item.amount.toFixed(2) : 0,
        orderId: item.orderId ? item.orderId : "N/A",
    })) : []


    return (
        <Layout style={{ backgroundColor: "#fff" }}>
            <Navbar defaultIndex={4} />

            <Layout>
                <Content>
                    <HeaderWithProfile title={"Successful Referrals"} />

                    <div className='overview'>
                        <div style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", margin: "auto" }}>
                            <Col span={12}>
                                <Heading3 weight="bold" color="oxfordBlue" text="Overview" />
                                {/* <h1 style={{fontFamily: 'Montserrat', fontSize: "20px", fontWeight: 600, color: "#10202D"}}>Overview</h1> */}
                            </Col>
                            <Col>
                                <Button className='add-patient-btn'><span><EditOutlined style={{ color: "#ffff",position:"relative",bottom:"3px",right:"6px" }} /></span> Create Referral</Button>
                            </Col>
                        </div>

                        <div className='overview-cards-container'>

                            <Card
                                className="overview-card"
                                width={100}
                                style={{ borderRadius: "12px", backgroundColor: 'rgba(11, 190, 120, 0.03)' }}
                            >
                                  <div className="overview-card-text-container" >
                                    <div className="overview-card-text">Total Referral</div>
                                    <div class="tooltip">
                                        <img src="/images/info-icon.svg" alt="info-icon" />
                                        <span class="tooltiptext">Number of purchases from your patients</span>
                                    </div>
                                </div>
                                <div className="home-overview">
                                <img className="home-overview-icon" src="/images/Referral.svg" alt="Refer Patient" />
                                    <span className="overview-card-number" style={{ color: "#0BBE78" }}>{successfulReferralList.length ? successfulReferralList.length : 0}</span>
                                </div>
                             
                            </Card>

                            <Card
                                className="overview-card"
                                width={100}
                                style={{ borderRadius: "10px", border: "1px solid #E5E5E5", backgroundColor: "#FCF1F7" }}
                            >
                                 <div className="overview-card-text-container" >
                                    <div className="overview-card-text">Referral Amount</div>
                                    <div class="tooltip">
                                        <img src="/images/info-icon.svg" alt="info-icon" />
                                        <span class="tooltiptext">Total commission earned</span>
                                    </div>
                                </div>
                                <div className="home-overview">
                                <img className="home-overview-icon" src="/images/Patients.svg" alt="Refer Patient" />
                                <span className="overview-card-number" style={{ color: "#DC4A9A" }}>{referralAmount.toFixed(2)}</span>
                                </div>
                             
                               
                            </Card>

                        </div>
                    </div>
                    <div className="patient-table">
                        <Table columns={columns} dataSource={data} />;
                    </div>
                </Content>
            </Layout>


        </Layout>
    );
};

export default SuccessfulReferral;