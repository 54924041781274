export const SUCCESSFUL_REFERRAL = 'SUCCESSFUL_REFERRAL';

export const SUCCESSFUL_REFERRAL_GET = `${SUCCESSFUL_REFERRAL} GET`;
export const SUCCESSFUL_REFERRAL_SET = `${SUCCESSFUL_REFERRAL} SET`;

export const getSuccessfulReferral = (payload) => ({
    type: SUCCESSFUL_REFERRAL_GET,
    payload,
});

export const setSuccessfulReferral = (payload) => ({
    type: SUCCESSFUL_REFERRAL_SET,
    payload,
});