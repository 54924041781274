import UviLogo from "../../common/uviLogo";

import "./navbar.css";

const PlanNavbar = () => {
  return (
    <div className="navbar-containers">
      <div>
        <UviLogo size="60px" />
      </div>
      <div></div>
    </div>
  );
};

export default PlanNavbar;
