import React, { useEffect, useState } from "react";

import { Body2,Body3 } from "../text";
import { getIcon } from "./inputUtil";
import "./Input.css";

const DropDown = ({
  lable,
  value,
  placeholder,
  required = false,
  icon,
  selected,
  dropDownOptions,
  onSelect,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [localSelected, setLocalSelected] = useState(selected);
  
  useEffect(() => {
    setLocalSelected(selected);
  }, [selected]);

  return (
    <div onClick={() => setOpen(!isOpen)} style={{}}>
      <div>
        {lable ? (
          <div className="text_input-lable">
            {icon ? (
              <div className="text_input-svg">{getIcon(icon)}</div>
            ) : null}

            <Body3
              text={required ? lable + "*" : lable}
              color="legend2"
              weight={"normal"}
            />
          </div>
        ) : null}
      </div>
      <div className="drop_down">
        <Body3
          text={localSelected || placeholder}
          color={localSelected ? "oxfordBlue" : "muted"}
          weight={"lightBold"}
        />
        <div className="drop_down-icon">{getIcon("chevDown")}</div>
      </div>
      {isOpen ? (
        <div className="drop_down-bottom_sheet">
          {dropDownOptions.map((ele, index) => (
            <div
              key={ele + index}
              className={`drop_down-bottom_sheet-elements ${
                localSelected && localSelected === ele
                  ? "drop_down-bottom_sheet-elements-selected"
                  : ""
              }`}
              onClick={() => {
                setLocalSelected(ele);
                onSelect(ele);
              }}
            >
              <Body2 text={ele} color={"legend2"} weight={"lightBold"} />
              {localSelected && localSelected === ele ? (
                <div className="drop_down-check_icon">{getIcon("check")}</div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DropDown;
