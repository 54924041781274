import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../config";
import UviLogo from "../common/uviLogo";
import ProfileCompletion from "../common/profileCompletionComp";
import { Heading2, Body2 } from "../elements/text";
import { Menu, Button, Card } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { setExpert } from "../../stores/actions/expert";
import { useSelector, useDispatch } from "react-redux";
import { expertDataSelector } from "../../stores/selectors/expert";
import { HomeOutlined, UsergroupAddOutlined, FileTextOutlined, CustomerServiceOutlined, SolutionOutlined } from '@ant-design/icons';

import "./HeaderWithProfile.css";

const HeaderWithProfile = ({ isHome, title }) => {

  const history = useHistory();

  const navList = [["Home", '/',1],["All Patient", '/patients',2], ["Prescriptions", '/prescriptions',3], ["Successful Referrals", '/successfulReferrals',4], ["Support", '/support',5]]
  const [cardShow, setCardShow] = useState(false);

  const dispatch = useDispatch();
  const [expertData, setExpertData] = useState({})
  const _expertData = useSelector((state) => expertDataSelector(state));

  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(ROOT_URL + "/profile", {
        withCredentials: true,
        credentials: "include",
        params: null,
      });
      if (resp && resp.data && resp.data.responseData)
        dispatch(setExpert(resp.data.responseData[0]));
    };
    getData();
  }, []);

  useEffect(() => {
    if (_expertData && _expertData.name) {
      setExpertData(_expertData)
    }
  }, [_expertData])

  return (
    <div className="header_with_profile-wrapper">
      <div className="header_with_profile-container">
        <div  style={{ marginTop: 15 }}>
          <div className="header_with_profile-desktopOnly">
          {isHome ? <Heading2 weight="bold" color="legend" text=" Welcome," /> : null}
          </div>
          
          <div className="doctor-name">

            <Heading2 weight="bold" color="legend" text={title} />
          </div>

          <div className="logo-ham" >
            <UviLogo />
            <Button onClick={() => setCardShow(!cardShow)} icon={<MenuOutlined />} >
        </Button>
          </div>

        </div>
        {/* <div className="profile-complition-mobile-only">
          <ProfileCompletion expertData={expertData} />
        </div> */}
      </div>
      <div className="header_with_profile-name_container">

        {/* <Heading2 dynamic={true} weight="bold" color="legend" text={title} /> */}

        <ProfileCompletion expertData={expertData} />

      </div>
      <div className="header_with_navbar">
        <Card
          style={{
            width: 347,
            marginLeft: "auto",
            zIndex: 10,
            display: cardShow ? "block" : "none",
            borderRadius: 20,
            position: "absolute",
            top:68,
          }}
        >
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {/* <a onClick={(e) => { history.push("/") }} style={{ color: "rgb(39, 80, 112)", fontWeight: 500, fontSize: 18, padding: "20px" }}>Home</a> */}
            
            {
              navList.map(
                (item) =>
                  <div style={{ marginTop: 20 }}>
                    <a onClick={(e) => { history.push(item[1]) }} style={{ color: "rgb(39, 80, 112)", fontWeight: 500, fontSize: 14, paddingLeft: "15px",display:"flex",flexDirection:"row",fontFamily:"Montserrat" }}>
                    <img src={item[2]===1?"/images/Home.svg": item[2]===2?"/images/PatientsIcon.svg": item[2]===3?"/images/PrescriptionIcon.svg": item[2]===4?"/images/RefIcon.svg": "/images/SupportIcon.svg"} style={{width:14, height:14 , position:"relative",top:"3px",marginRight:"5px"}}/>
                    {item[0]}
                    </a>
                  
                  
                  </div>
              )
            }
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HeaderWithProfile;
