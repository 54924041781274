import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../config";
import moment from "moment/moment";
import Swal from "sweetalert2";

import { expertDataSelector } from "../../stores/selectors/expert";
import TextInput from "../../components/elements/input/textInput";
import DropDown from "../../components/elements/input/dropDown";
import { Heading2, Body2, Body3 } from "../../components/elements/text";
import InlineButton from "../../components/elements/buttons/InlineButton";
import PrimaryButton from "../../components/elements/buttons/primaryButton";
import PlanNavbar from "../../components/elements/navbar/planNavbar";
import Navbar from "../../components/common/navbar/Navbar";
import HeaderWithProfile from "../../components/headerWithProfile";

import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from "antd/lib/layout/layout";

import "./Referral.css";

const planDurations = ["3 - Months", "6 - Months", "12 - Months"];
const plans = ["PCOS Care Plan", "Thyroid Care Plan"];
const followUpNumbers = Array.from(Array(31).keys());
followUpNumbers.shift();
const genders = ["Male", "Female", "Transgender", "Non-binary"];

const Referral = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const expertData = useSelector((state) => expertDataSelector(state));
  const [patientName, setPatientName] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [whatsappNumber, setWhatsappNumeber] = useState("");
  const [symptomHistory, setSymptomHistory] = useState([""]);
  const [labtesting, setLabtesting] = useState([{ name: "", remarks: "" }]);
  const [planDuration, setPlanDuration] = useState("");
  const [plan, setPlan] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [remarks, setMarks] = useState("");
  const [diagnosisNote, setDiagnosisNote] = useState("");

  const [reRnder, setReRender] = useState(false);

  const validateForm = (recommended_plan_id) => {
    if (!patientName || patientName.length < 3) {
      Swal.fire("Please enter patient's name");
      return false;
    }
    if (!age) {
      Swal.fire("Please enter patient's age");
      return false;
    }
    if (!gender) {
      Swal.fire("Please enter patient's gender");
      return false;
    }
    if (!whatsappNumber || whatsappNumber.length !== 10) {
      Swal.fire("Please enter patient's 10 digit WhatsApp number");
      return false;
    }
    if (!recommended_plan_id && labtesting[0].name === "") {
      Swal.fire("Please refer a program or a lab test");
      return false;
    }
    return true;
  };

  //-------------Symptoms---------------//
  const addSymptomHistory = () => {
    const current = symptomHistory;
    current.push("");
    setSymptomHistory(current);
    setReRender(!reRnder);
  };

  const removeSymptomHistory = (index) => {
    const current = symptomHistory;
    current.splice(index, 1);
    setSymptomHistory(current);
    setReRender(!reRnder);
  };

  const updateSymptomHistory = (text, index) => {
    const current = symptomHistory;
    current[index] = text;
    setSymptomHistory(current);
    setReRender(!reRnder);
  };

  //-------------Labtests---------------//
  const addLabtesting = () => {
    const current = labtesting;
    current.push({ name: "", remarks: "" });
    setLabtesting(current);
    setReRender(!reRnder);
  };

  const removeLabtesting = (index) => {
    const current = labtesting;
    current.splice(index, 1);
    setLabtesting(current);
    setReRender(!reRnder);
  };

  const updateLabtesting = (text, key, index) => {
    const current = labtesting;
    current[index][key] = text;
    setLabtesting(current);
    setReRender(!reRnder);
  };

  const getPlanId = (plan, planDuration) => {
    let result;
    const str = `${plan}-${planDuration}`;
    console.log("=========================");
    console.log(str, "PCOS Care Plan-3 - Months");
    console.log("=========================");
    switch (str) {
      case "PCOS Care Plan-3 - Months":
        result = "PLAN002";
        break;
      case "PCOS Care Plan-6 - Months":
        result = "PLAN003";
        break;
      case "PCOS Care Plan-12 - Months":
        result = "PLAN004";
        break;
      case "Thyroid Care Plan-3 - Months":
        result = "PLAN049";
        break;
      case "Thyroid Care Plan-6 - Months":
        result = "PLAN050";
        break;
      case "Thyroid Care Plan-12 - Months":
        result = "PLAN051";
        break;
      default:
        break;
    }
    return result;
  };

  const handleSubmit = () => {
    const recommended_plan_id = getPlanId(plan, planDuration);

    const valid = validateForm(recommended_plan_id);
    if (valid === false) {
      return null;
    }

    const payload = {
      doctor_id: expertData.doctor_id,
      name: patientName,
      age: age,
      gender: gender,
      height: height,
      weight: weight,
      calling_number: null,
      whatsapp_number: "91" + whatsappNumber,
      diagnosis: diagnosis,
      diagnosis_note: diagnosisNote,
      recommended_plan_id: recommended_plan_id,
      symptoms: symptomHistory,
      recommended_lab_tests: labtesting,
    };
    const makeCall = async () => {
      const resp = await axios.post(ROOT_URL + "/referral", payload, {
        withCredentials: true,
        credentials: "include",
      });
      if (
        resp &&
        resp.data &&
        resp.data.responseStatus &&
        resp.data.responseStatus.message === "Referral sent"
      ) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Referral sent",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/");
      }
    };
    makeCall();
  };

  return (
    <Layout>
      <Navbar defaultIndex={4} />

      <Layout>
        <Content>
          <HeaderWithProfile title={"Refer a Patient"} />

          <div>
            <div className="profile-form-container">
              <div className="referral-title">
                <Heading2
                  text="Patient Details"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              <div className="prescription-input-container-wrapper">
                <div className="prescription-input-container margin-bottom-all">
                  <TextInput
                    lable={"Patient's Name"}
                    placeholder={"E.g. Puja Sharma"}
                    value={patientName}
                    onChange={(e) => setPatientName(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="prescription-input-container margin-bottom-all">
                  <DropDown
                    lable={"Gender"}
                    placeholder={"E.g. Female"}
                    selected={gender}
                    onSelect={(e) => setGender(e)}
                    type="text"
                    required={true}
                    dropDownOptions={genders}
                  />
                </div>
                <div className="prescription-input-container">
                  <TextInput
                    lable={"Patient's WhatsApp Number"}
                    placeholder={"E.g. 9845456775"}
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumeber(e)}
                    type="text"
                    required={true}
                  />
                </div>
              </div>


              <div className="recommendation-container">
                <div className="referral-title">
                  <Heading2
                    text="Care Program Referral"
                    weight="semitBold"
                    color="oxfordBlue"
                  />
                </div>
                <div className="prescription-input-container-wrapper">
                  <div
                    className={`prescription-input-container margin-bottom-only-mobile`}
                  >
                    <DropDown
                      placeholder={"E.g. 3-months"}
                      selected={planDuration}
                      onSelect={(e) => setPlanDuration(e)}
                      type="text"
                      required={true}
                      icon="degree"
                      dropDownOptions={planDurations}
                    />
                  </div>
                  <div className="prescription-input-container margin-bottom-only-mobile">
                    <DropDown
                      placeholder={"E.g. PCOS Care 3 - Months"}
                      selected={plan}
                      onSelect={(e) => setPlan(e)}
                      type="text"
                      required={true}
                      icon="degree"
                      dropDownOptions={plans}
                    />
                  </div>
                </div>
              </div>

              {/* ------------------ */}
              <div
                style={{
                  // marginTop: "60px",
                  // paddingBottom: "40px",
                  paddingTop: "60px",
                  width: "157px",
                  height: "48px"
                }}
              >
                <PrimaryButton onClick={() => handleSubmit()} lable="Submit" />
              </div>
              <div style={{ marginTop: "80px" }}></div>
            </div>
          </div>

        </Content>
      </Layout>
    </Layout>
  );
};

export default Referral;
