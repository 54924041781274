export const PRESCRIPTION = '[PRESCRIPTION]';
export const PRESCRIPTIONS = '[PRESCRIPTIONS]';

export const PRESCRIPTION_CREATE = `${PRESCRIPTION} CREATE`;
export const PRESCRIPTIONS_GET = `${PRESCRIPTIONS} GET`;
export const PRESCRIPTIONS_SET = `${PRESCRIPTIONS} SET`;

export const createPrescription = (data) => ({
    type: PRESCRIPTION_CREATE,
    payload: data,
});

export const getPrescriptions = (data) => ({
    type: PRESCRIPTIONS_GET,
    payload: data,
});

export const setPrescriptions = (data) => ({
    type: PRESCRIPTIONS_SET,
    payload: data,
});