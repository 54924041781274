import "./ProfilePicture.css";
import { ReactComponent as SVGDefault } from "../../elements/svg/default-profile.svg";
import { ReactComponent as SVGCamera } from "../../elements/svg/camera.svg";

import "./ProfilePicture.css";

const ProfilePicture = ({ expertData, handleFileUpload, profileURL }) => {
  console.log("111111111111111111profileURL", profileURL);
  if (profileURL || expertData.profile_picture) {
    return (
      <div className="profile_picture_container">
        <img
          style={{
            width: "96px",
            height: "96px",
            borderRadius: 50,
            backgroundColor: "#fff",
          }}
          src={profileURL || expertData.profile_picture}
          alt="doge"
        />
        <label>
          <div className="profile_picture_upload">
            <input
              style={{ display: "none" }}
              type={"file"}
              // placeholder={placeholder || ""}
              // value={value}
              // onChange={(e) => onChange(e.target.value)}
              onChange={(e) => handleFileUpload(e)}
            />
            <SVGCamera />
          </div>
        </label>
      </div>
    );
  }
  return (
    <div className="profile_picture_container">
      <div
        style={{
          width: "96px",
          height: "96px",
          borderRadius: 50,
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "96px",
            height: "96px",
          }}
        >
          <div style={{ width: "50px", height: "50px" }}>
            <SVGDefault />
          </div>
        </div>
      </div>
      <label>
        <div className="profile_picture_upload">
          <input
            style={{ display: "none" }}
            type={"file"}
            // placeholder={placeholder || ""}
            // value={value}
            // onChange={(e) => onChange(e.target.value)}
            onChange={(e) => handleFileUpload(e)}
          />
          <SVGCamera />
        </div>
      </label>
    </div>
  );
};

export default ProfilePicture;
