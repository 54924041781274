import ProfilePicture from "../common/profilePicture";
import { Body1, Body2, Body3, Heading2 } from "../elements/text";
import VerifiedIcon from "../common/verified";
import UviLogo from "../common/uviLogo";

import "./EditProfile.css";

const EditProfile = ({ expertData, handleFileUpload, profileURL }) => {
  const isOnboarded = expertData.onboarded;
  if (isOnboarded === 0) {
    return (
      <div className="edit_profile-container">
        <div className="edit_profile-container-logo">
          <UviLogo />
        </div>
        <div style={{ width: "100%" }}>
          <div></div>
          <Heading2
            dynamic={true}
            text={`Hello, Dr. ${expertData.name}`}
            weight="bold"
            color={"oxfordBlue"}
          />
          <Heading2
            dynamic={true}
            text={
              <p>
                Welcome to <span style={{ color: "#F5735A" }}>Uvi</span> Health
              </p>
            }
            weight="bold"
            color={"oxfordBlue"}
          />
          <Body2
            text={
              "You're 1 step away from completing your onboarding. Verify your details to get started"
            }
            weight="lightBold"
            color={"oxfordBlue"}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="edit_profile-container" style={{ alignItems: "center" }}>
      <div>
        <ProfilePicture
          expertData={expertData}
          handleFileUpload={handleFileUpload}
          profileURL={profileURL}
        />
      </div>
      <div className="edit_profile-name_container">
        <Body1 weight="bold" color="legend" text={"Dr. " + expertData.name} />
        <div className="edit_profile-name_container-icon">
          <VerifiedIcon size="22px" />
        </div>
      </div>
      <div>
        <Body3
          weight="normal"
          color="legend"
          text={expertData.specialisation}
        />
      </div>
    </div>
  );
};

export default EditProfile;
