import React, { useState } from "react";
import { Body2, Body3 } from "../text";
import { getIcon } from "./inputUtil";
import "./Input.css";
import { ReactComponent as SVGClose } from "../svg/x-circle.svg";

const FileInput = ({
  lable,
  icon,
  required,
  placeholder,
  value,
  onChange,
  file,
  removeHandler,
}) => {
  const [active, setActive] = useState(false);
  return (
    <div>
      <div>
        {lable && (
          <div className="text_input-lable">
            {icon ? (
              <div className="text_input-svg">{getIcon(icon)}</div>
            ) : null}

            <Body2
              text={required ? lable + "*" : lable}
              color={"legend2"}
              weight={"normal"}
            />
          </div>
        )}
      </div>
      <div className="radio_input-container">
        <label>
          <div
            className={`text_input file_input ${!file ? "radio_active" : ""}`}
            style={{ cursor: "pointer" }}
          >
            <input
              style={{ display: "none" }}
              type={"file"}
              placeholder={placeholder || ""}
              value={value}
              // onChange={(e) => onChange(e.target.value)}
              onChange={(e) => onChange(e)}
            />
            <Body2
              text={!file ? "File Uploaded" : "Choose File"}
              color={!file ? "magentaDye" : "legend2"}
              weight={"lightBold"}
            />
          </div>
        </label>
        <div className="file_input-file_name file-name">
          <a
            href={file}
            to={file}
            download
            target={"_blank"}
          >
            <Body3
              dynamic={true}
              text={file ? "Download file" : "No File Chosen"}
              color={file ? "green" : "legend2"}
              weight={"normal"}
            />
          </a>

        </div>
        <div
          onClick={() => removeHandler()}
          className="file_input-file_name"
          style={{ opacity: "0.5", cursor: "pointer" }}
        >
          <SVGClose />
        </div>
      </div>
    </div>
  );
};

export default FileInput;
