import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/merriweather-sans";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { loginCheck } from "./stores/actions/login";
import { authSelector } from "./stores/selectors/auth";

import Login from "./pages/auth/login";
import Home from "./pages/home";
import NewPrescriptionForm from "./pages/prescription/create";
import PendingLoader from "./components/common/PendingLoader";
import Profile from "./pages/profile";
import ViewPrescriptions from "./pages/prescription/view";
import Referral from "./pages/referal";
import Register from "./pages/registeration";
import Patient from "./pages/patient/patientsList/patient";
import Prescription from "./pages/prescription/prescription";
import ViewPatient from "./pages/patient/viewPatient/viewPatient";
import SuccessfulReferral from "./pages/successfulReferral/successfulReferral";
import Support from "./pages/support/support";
import UpdatePatient from "./pages/patient/create/updatePatient";

import 'antd/dist/antd.css';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <ProtectedRoute path="/home" exact component={Home} />
        <ProtectedRoute path="/patients" exact component={Patient} />
        <ProtectedRoute path="/prescriptions" exact component={Prescription} />
        <ProtectedRoute path="/viewPatient/:calling_number" exact component={ViewPatient} />
        <ProtectedRoute path="/successfulReferrals" exact component={SuccessfulReferral} />
        <ProtectedRoute path="/updatePatient/:patient_id" exact component={UpdatePatient} />
        <ProtectedRoute path="/support" exact component={Support} />
        <ProtectedRoute
          path="/newPrescription"
          exact
          component={NewPrescriptionForm}
        />
        <ProtectedRoute path="/profile" exact component={Profile} />
        <ProtectedRoute
          path="/view-prescriptions"
          exact
          component={ViewPrescriptions}
        />
        <ProtectedRoute path="/referral" exact component={Referral} />

        <ProtectedRoute path="/" exact component={Home} />
      </Switch>
    </Router>
  );
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loginCheck());
  }, [dispatch]);
  let isAuthenticated = useSelector((state) => authSelector(state));
  return (
    <Route 
      {...rest}
      render={(props) =>
        isAuthenticated === "pending" ? (
          <PendingLoader />
        ) : isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default App;
