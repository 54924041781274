import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../../config";
import moment from "moment/moment";
import Swal from "sweetalert2";

import { expertDataSelector } from "../../../stores/selectors/expert";
import TextInput from "../../../components/elements/input/textInput";
import DropDown from "../../../components/elements/input/dropDown";
import { Heading2, Body2, Body3 } from "../../../components/elements/text";
import InlineButton from "../../../components/elements/buttons/InlineButton";
import { ReactComponent as SVGCheck } from "../../../components/elements/svg/check-plain.svg";
import PrimaryButton from "../../../components/elements/buttons/primaryButton";
import PlanNavbar from "../../../components/elements/navbar/planNavbar";
import Navbar from "../../../components/common/navbar/Navbar";
import HeaderWithProfile from "../../../components/headerWithProfile";

import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from "antd/lib/layout/layout";

import "./CreatePrescription.css";

const planDurations = ["3 - Months", "6 - Months", "12 - Months"];
const plans = ["PCOS Care Plan", "Thyroid Care Plan"];
const followUpNumbers = Array.from(Array(31).keys());
followUpNumbers.shift();
const followUpTypes = ["Days", "Months"];
const genders = ["Male", "Female", "Transgender", "Non-binary"];

const NewPrescriptionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const expertData = useSelector((state) => expertDataSelector(state));
  const [patientName, setPatientName] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [whatsappNumber, setWhatsappNumeber] = useState("");
  const [symptomHistory, setSymptomHistory] = useState([""]);
  const [conditionHistory, setConditionHistory] = useState([""]);
  const [familyHistory, setFamilyHistory] = useState([
    { condition: "", relation: "" },
  ]);
  const [currentMedications, setCurrentMedications] = useState([
    { name: "", remark: "" },
  ]);
  const [prescriptionItem, setPrescriptionItem] = useState([
    { name: "", dose: "", frequency: "", duration: "", remarks: "" },
  ]);
  const [labtesting, setLabtesting] = useState([
    { name: "", remarks: "", lab: "" },
  ]);
  const [advise, setAdvice] = useState([""]);
  const [recommendProgram, setRecommendProgram] = useState(false);
  const [planDuration, setPlanDuration] = useState("");
  const [plan, setPlan] = useState("");
  const [followUp, setFollowUp] = useState("");
  const [followUpType, setFollowUpTypes] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [diagnosisNote, setDiagnosisNote] = useState("");
  const [fetching, setFetching] = useState(false);

  const [reRnder, setReRender] = useState(false);

  console.log("expertData", expertData);
  useEffect(() => {
    if (!expertData) { //|| !expertData.name
      console.log("expertData", expertData);
      history.push("/");
    }
  }, [expertData]);

  const validateForm = () => {
    if (!patientName || patientName.length < 3) {
      Swal.fire("Please enter patient's name");
      return false;
    }
    if (!age) {
      Swal.fire("Please enter patient's age");
      return false;
    }
    if (!gender) {
      Swal.fire("Please enter patient's gender");
      return false;
    }
    if (!whatsappNumber || whatsappNumber.length !== 10) {
      Swal.fire("Please enter patient's 10 digit WhatsApp number");
      return false;
    }
    if (prescriptionItem[0].name === "" && labtesting[0].name === "") {
      console.log('No prescriptions are added');
      Swal.fire("No prescriptions are added");
      return false;
    }
    return true;
  };

  //-------------Symptoms---------------//
  const addSymptomHistory = () => {
    const current = symptomHistory;
    current.push("");
    setSymptomHistory(current);
    setReRender(!reRnder);
  };

  const removeSymptomHistory = (index) => {
    const current = symptomHistory;
    current.splice(index, 1);
    setSymptomHistory(current);
    setReRender(!reRnder);
  };

  const updateSymptomHistory = (text, index) => {
    const current = symptomHistory;
    current[index] = text;
    setSymptomHistory(current);
    setReRender(!reRnder);
  };

  //-------------Condition---------------//
  const addConditionHistory = () => {
    const current = conditionHistory;
    current.push("");
    setConditionHistory(current);
    setReRender(!reRnder);
  };

  const removeConditionHistory = (index) => {
    const current = conditionHistory;
    current.splice(index, 1);
    setConditionHistory(current);
    setReRender(!reRnder);
  };

  const updateConditionHistory = (text, index) => {
    const current = conditionHistory;
    current[index] = text;
    setConditionHistory(current);
    setReRender(!reRnder);
  };

  //-------------Family---------------//
  const addFamilyHistory = () => {
    const current = familyHistory;
    current.push({ condition: "", relation: "" });
    setFamilyHistory(current);
    setReRender(!reRnder);
  };

  const removeFamilyHistory = (index) => {
    const current = familyHistory;
    current.splice(index, 1);
    setFamilyHistory(current);
    setReRender(!reRnder);
  };

  const updateFamilyHistory = (text, key, index) => {
    const current = familyHistory;
    current[index][key] = text;
    setFamilyHistory(current);
    setReRender(!reRnder);
  };

  //-------------Medication---------------//
  const addCurrentMedication = () => {
    const current = currentMedications;
    current.push({ name: "", remark: "" });
    setCurrentMedications(current);
    setReRender(!reRnder);
  };

  const removeCurrentMedication = (index) => {
    const current = currentMedications;
    current.splice(index, 1);
    setCurrentMedications(current);
    setReRender(!reRnder);
  };

  const updateCurrentMedication = (text, key, index) => {
    const current = currentMedications;
    current[index][key] = text;
    setCurrentMedications(current);
    setReRender(!reRnder);
  };

  //-------------PrescriptionItem---------------//
  const addPrescriptionItem = () => {
    const current = prescriptionItem;
    current.push({
      name: "",
      dose: "",
      frequency: "",
      duration: "",
      remarks: "",
    });
    setPrescriptionItem(current);
    setReRender(!reRnder);
  };

  const removePrescriptionItem = (index) => {
    const current = prescriptionItem;
    current.splice(index, 1);
    setPrescriptionItem(current);
    setReRender(!reRnder);
  };

  const updatePrescriptionItem = (text, key, index) => {
    const current = prescriptionItem;
    current[index][key] = text;
    setPrescriptionItem(current);
    setReRender(!reRnder);
  };

  //-------------PrescriptionItem---------------//
  const addLabtesting = () => {
    const current = labtesting;
    current.push({ name: "", remarks: "", lab: "" });
    setLabtesting(current);
    setReRender(!reRnder);
  };

  const removeLabtesting = (index) => {
    const current = labtesting;
    current.splice(index, 1);
    setLabtesting(current);
    setReRender(!reRnder);
  };

  const updateLabtesting = (text, key, index) => {
    const current = labtesting;
    current[index][key] = text;
    setLabtesting(current);
    setReRender(!reRnder);
  };

  //-------------Symptoms---------------//
  const addAdvise = () => {
    const current = advise;
    current.push("");
    setAdvice(current);
    setReRender(!reRnder);
  };

  const removeAdvise = (index) => {
    const current = advise;
    current.splice(index, 1);
    setAdvice(current);
    setReRender(!reRnder);
  };

  const updateAdvise = (text, index) => {
    const current = advise;
    current[index] = text;
    setAdvice(current);
    setReRender(!reRnder);
  };

  const getTimestamp = (followUp) => {
    const timestamp = moment().add(followUp, followUpType);
    return timestamp.format("YYYY-MM-DD");
  };

  const getPlanId = (plan, planDuration) => {
    let result;
    const str = `${plan}-${planDuration}`;
    console.log("=========================");
    console.log(str, "PCOS Care Plan-3 - Months");
    console.log("=========================");
    switch (str) {
      case "PCOS Care Plan-3 - Months":
        result = "PLAN002";
        break;
      case "PCOS Care Plan-6 - Months":
        result = "PLAN003";
        break;
      case "PCOS Care Plan-12 - Months":
        result = "PLAN004";
        break;
      case "Thyroid Care Plan-3 - Months":
        result = "PLAN049";
        break;
      case "Thyroid Care Plan-6 - Months":
        result = "PLAN050";
        break;
      case "Thyroid Care Plan-12 - Months":
        result = "PLAN051";
        break;
      default:
        break;
    }
    return result;
  };

  const handleSubmit = () => {
    const followup_date = getTimestamp(followUp, followUpType);
    const recommended_plan_id = getPlanId(plan, planDuration);

    const validate = validateForm();
    if (validate === false) {
      return null;
    }

    const payload = {
      doctor_id: expertData.doctor_id,
      name: patientName,
      age: age,
      gender: gender,
      height: height,
      weight: weight,
      calling_number: null,
      whatsapp_number: "91" + whatsappNumber,
      diagnosis: diagnosis,
      diagnosis_note: diagnosisNote,
      recommended_plan_id: recommended_plan_id,
      followup_date: followup_date,
      symptoms: symptomHistory,
      prev_diagnosis: conditionHistory,
      family_history: familyHistory,
      current_medications: currentMedications,
      prescription_items: prescriptionItem,
      recommended_lab_tests: labtesting,
      lifestyle_advice: advise,
    };
    setFetching(true);
    const makeCall = async () => {
      const resp = await axios.post(ROOT_URL + "/prescription", payload, {
        withCredentials: true,
        credentials: "include",
      });
      if (
        resp &&
        resp.data &&
        resp.data.responseStatus &&
        resp.data.responseStatus.message === "presc created"
      ) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "presc created",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/");
      }
      setFetching(false);
    };
    makeCall();
  };

  return (
    <Layout>
      <Navbar defaultIndex={3} />

      <Layout>
        <Content>

          <HeaderWithProfile title={"Create a Prescription"} />

          <div>
            {/* <Navbar /> */}
            <div className="presc-form-container">
              <div className="presc_form_container-title">
                <Heading2
                  text="Patient Details"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              <div className="presc-input-container-wrapper">
                <div className="presc-input-container margin-bottom-all">
                  <TextInput
                    lable={"Name"}
                    placeholder={"E.g. Puja Sharma"}
                    value={patientName}
                    onChange={(e) => setPatientName(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="presc-input-container margin-bottom-all">
                  <TextInput
                    lable={"Age"}
                    placeholder={"E.g. 29"}
                    value={age}
                    onChange={(e) => setAge(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="presc-input-container margin-bottom-all">
                  <DropDown
                    lable={"Gender"}
                    placeholder={"E.g. Female"}
                    selected={gender}
                    onSelect={(e) => setGender(e)}
                    type="text"
                    required={true}
                    dropDownOptions={genders}
                  />
                </div>
              </div>
              <div className="presc-input-container-wrapper">
                <div className="presc-input-container margin-bottom-only-mobile">
                  <TextInput
                    lable={"Height in cm"}
                    placeholder={"E.g. 160"}
                    value={height}
                    onChange={(e) => setHeight(e)}
                    type="text"
                  />
                </div>
                {/* <div className="presc-input-container margin-bottom-only-mobile">
                  <TextInput
                    lable={"Patient's Weight in kg"}
                    placeholder={"E.g. 50"}
                    value={weight}
                    onChange={(e) => setWeight(e)}
                    type="text"
                  />
                </div> */}
                <div className="presc-input-container">
                  <TextInput
                    lable={"WhatsApp Number"}
                    placeholder={"E.g. 9845456775"}
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumeber(e)}
                    type="text"
                    required={true}
                  />
                </div>
              </div>
              {/* Medical History */}
              <div className="presc_form_container-title">
                <Heading2
                  text="Medical History"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              <div className="presc_form_medical_history">
                <div className="presc_form_container">
                  <div className="presc-input-subheading">
                    <Body3
                      text={"List all the symptoms reported by the patient"}
                      color="legend2"
                      weight={"normal"}
                    />
                  </div>
                  {symptomHistory.map((ele, index) => (
                    <div className="presc-input-container margin-bottom-all">
                      <TextInput
                        placeholder={
                          "E.g. Fever, Cough, Stomach Ache, Bloating, Irregular Periods"
                        }
                        value={ele}
                        onChange={(e) => updateSymptomHistory(e, index)}
                        type="text"
                        required={true}
                      />
                      {index !== 0 && (
                        <div
                          className="presc-input-inline-remove"
                          onClick={() => removeSymptomHistory(index)}
                        >
                          <Body3 text={`Remove`} color="danger" weight={"semitBold"} />
                        </div>
                      )}
                    </div>
                  ))}

                  <div style={{ paddingBottom: "20px", marginTop: "-10px" }}>
                    <InlineButton
                      lable="Add more"
                      icon="plus"
                      onClick={() => addSymptomHistory()}
                    />
                  </div>
                </div>
                <div className="presc_form_container">
                  <div className="presc-input-subheading">

                    <Body3
                      text={"Conditions that's been previously diagnosed with"}
                      color="legend2"
                      weight={"normal"}
                    />
                  </div>
                  {conditionHistory.map((ele, index) => (
                    <div className="presc-input-container margin-bottom-all">
                      <TextInput
                        placeholder={"E.g. PCOS"}
                        value={ele}
                        onChange={(e) => updateConditionHistory(e, index)}
                        type="text"
                        required={true}
                      />
                      {index !== 0 && (
                        <div
                          className="presc-input-inline-remove"
                          onClick={() => removeConditionHistory(index)}
                        >
                          <Body3 text={`Remove`} color="danger" weight={"semitBold"} />
                        </div>
                      )}
                    </div>
                  ))}
                  <div style={{ paddingBottom: "20px", marginTop: "-10px" }}>
                    <InlineButton
                      lable="Add more"
                      icon="plus"
                      onClick={() => addConditionHistory()}
                    />
                  </div>
                </div>
              </div>
              <div className="presc_form_medical_history">
                <div className="presc_form_container">
                  <div className="presc-input-subheading">
                    <Body3
                      text={
                        "List patient's family history of medical conditions"
                      }
                      color="legend2"
                      weight={"normal"}
                    />

                  </div>
                  {familyHistory.map((ele, index) => (
                    <>
                      {index !== 0 ? <div style={{ height: "20px" }} /> : null}
                      <div className="presc_form_container-education_wrapper">
                        <div >
                          <div
                            className={`presc-medical-container ${index === 0
                              ? "margin-bottom-all"
                              : "margin-bottom-only-mobile"
                              }`}
                          >
                            <TextInput
                              placeholder={"Name of Medical Condition"}
                              value={ele.condition}
                              onChange={(e) =>
                                updateFamilyHistory(e, "condition", index)
                              }
                              type="text"
                              required={true}
                            />
                          </div>
                          <div className="presc-medical-container margin-bottom-only-mobile">
                            <TextInput
                              placeholder={"Family Member (E.g. Father/ Mother)"}
                              value={ele.relation}
                              onChange={(e) =>
                                updateFamilyHistory(e, "relation", index)
                              }
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        {index !== 0 && (
                          <div style={{ marginTop: "40px" }}>
                            <div
                              className="presc_form_container-education_delete"
                              onClick={() => removeFamilyHistory(index)}
                            >
                              <Body3
                                text={`Remove`}
                                color="danger"
                                weight={"semitBold"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                  <div style={{ paddingBottom: "20px", marginTop: "10px" }}>
                    <InlineButton
                      lable="Add more"
                      icon="plus"
                      onClick={() => addFamilyHistory()}
                    />
                  </div>
                </div>
                <div>
                  <div className="presc-input-subheading">
                    <Body3
                      text={"List current medications patient is taking"}
                      color="legend2"
                      weight={"normal"}
                    />

                  </div>
                  {currentMedications.map((ele, index) => (
                    <>
                      {index !== 0 ? <div style={{ height: "20px" }} /> : null}
                      <div className="presc_form_container-education_wrapper">
                        <div >
                          <div
                            className={`presc-medical-container ${index === 0
                              ? "margin-bottom-all"
                              : "margin-bottom-only-mobile"
                              }`}
                          >
                            <TextInput
                              placeholder={"Medicine Name"}
                              value={ele.name}
                              onChange={(e) =>
                                updateCurrentMedication(e, "name", index)
                              }
                              type="text"
                              required={true}
                            />
                          </div>
                          <div className="presc-medical-container margin-bottom-only-mobile">
                            <TextInput
                              placeholder={"Remarks"}
                              value={ele.remark}
                              onChange={(e) =>
                                updateCurrentMedication(e, "remark", index)
                              }
                              type="text"
                              required={true}
                            />
                          </div>
                        </div>
                        {index !== 0 && (
                          <div style={{ marginTop: "40px" }}>
                            <div
                              className="presc_form_container-education_delete"
                              onClick={() => removeCurrentMedication(index)}
                            >
                              <Body3
                                text={`Remove`}
                                color="danger"
                                weight={"semitBold"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                  <div style={{ paddingBottom: "0px", marginTop: "10px" }}>
                    <InlineButton
                      lable="Add more"
                      icon="plus"
                      onClick={() => addCurrentMedication()}
                    />
                  </div>
                </div>
              </div>
              {/* diagnosis of patient */}
              <div >
                <div className="presc_form_container-title">
                  <Heading2 text="Diagnosis" weight="semitBold" color="oxfordBlue" />
                </div>
                <div>
                  <div className="presc-input-subheading">
                    <Body3
                      text={"What is the diagnosis of patient's current symptoms?"}
                      color="legend2"
                      weight={"normal"}
                    />

                  </div>
                  <div className="presc-input-container-wrapper">
                    <div className="presc-input-container margin-bottom-only-mobile">
                      <TextInput
                        placeholder={"Diagnosis"}
                        value={diagnosis}
                        onChange={(e) => setDiagnosis(e)}
                        type="text"
                        required={true}
                      />
                    </div>
                    <div className="presc-input-container">
                      <TextInput
                        placeholder={"Remarks"}
                        value={diagnosisNote}
                        onChange={(e) => setDiagnosisNote(e)}
                        type="text"
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Rx Prescription */}
              <div className="presc_form_container-title">
                <Heading2
                  text="Rx Prescription"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              {prescriptionItem.map((ele, index) => (
                <>
                  {index !== 0 ? <div style={{ height: "20px" }} /> : null}
                  <div className="presc_form_container-prescription_wrapper">
                    <div className="presc-input-container-wrapper">
                      <div className="presc-input-prescription margin-bottom-all">
                        <TextInput
                          placeholder={"Medicine Name"}
                          value={ele.name}
                          onChange={(e) => updatePrescriptionItem(e, "name", index)}
                          type="text"
                          required={true}
                        />
                      </div>
                      <div className="presc-input-prescription margin-bottom-all">
                        <TextInput
                          placeholder={"Dose (E.g. 5 tablets)"}
                          value={ele.dose}
                          onChange={(e) => updatePrescriptionItem(e, "dose", index)}
                          type="text"
                          required={true}
                        />
                      </div>
                      <div className="presc-input-prescription margin-bottom-all">
                        <TextInput
                          placeholder={"Frequency (E.g. 1-0-1 After meals)"}
                          value={ele.frequency}
                          onChange={(e) =>
                            updatePrescriptionItem(e, "frequency", index)
                          }
                          type="text"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="presc-input-container-wrapper">
                      <div
                        className={`presc-input-prescription ${index === 0
                          ? "margin-bottom-all"
                          : "margin-bottom-only-mobile"
                          }`}
                      >
                        <TextInput
                          placeholder={"Duration (E.g. 10 days, 3 months)"}
                          value={ele.duration}
                          onChange={(e) =>
                            updatePrescriptionItem(e, "duration", index)
                          }
                          type="text"
                          required={true}
                        />
                      </div>
                      <div className="presc-input-prescription margin-bottom-only-mobile">
                        <TextInput
                          placeholder={"Remarks (E.g. Antibiotics for fever)"}
                          value={ele.remarks}
                          onChange={(e) =>
                            updatePrescriptionItem(e, "remarks", index)
                          }
                          type="text"
                          required={true}
                        />
                      </div>
                    </div>
                    {index !== 0 ? (
                      <div style={{ marginTop: "40px" }}>
                        <div
                          onClick={() => removePrescriptionItem(index)}
                          className="presc_form_container-education_delete"
                        >
                          <Body3
                            text={`Remove`}
                            color="danger"
                            weight={"semitBold"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ))}
              <div style={{ marginTop: "10px", paddingBottom: "0px" }}>
                <InlineButton
                  lable="Add more"
                  icon="plus"
                  onClick={() => addPrescriptionItem()}
                />
              </div>
              {/* Lab Testing Recommended */}
              <div className="presc_form_container-title">
                <Heading2
                  text="Lab Testing Recommended"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              {labtesting.map((ele, index) => (
                <>
                  {index !== 0 ? <div style={{ height: "20px" }} /> : null}
                  <div className="presc_form_container-lab-test_wrapper">
                    <div className="presc-input-container-wrapper">
                      <div className="presc-input-lab-test margin-bottom-all">
                        <TextInput
                          placeholder={"Tests Name (E.g. LH, FSH, Prolactin, AmH)"}
                          value={ele.name}
                          onChange={(e) => updateLabtesting(e, "name", index)}
                          type="text"
                          required={true}
                        />
                      </div>

                      <div className="presc-input-container-wrapper">
                        <div className="presc-input-lab-test margin-bottom-all">
                          <TextInput
                            placeholder={"Remarks (E.g. Fasting Needed)"}
                            value={ele.remarks}
                            onChange={(e) => updateLabtesting(e, "remarks", index)}
                            type="text"
                            required={true}
                          />
                        </div>
                        <div className="presc-input-lab-test margin-bottom-all">
                          <TextInput
                            placeholder={"Recommended Lab (E.g. Uvi Health)"}
                            value={ele.lab}
                            onChange={(e) => updateLabtesting(e, "lab", index)}
                            type="text"
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                    {index !== 0 ? (
                      <div style={{ marginTop: "40px" }}>
                        <div
                          onClick={() => removeLabtesting(index)}
                          className="presc_form_container-education_delete"
                        >
                          <Body3
                            text={`Remove`}
                            color="danger"
                            weight={"semitBold"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ))}
              <div style={{ paddingBottom: "0px", marginTop: "10px" }}>
                <InlineButton
                  lable="Add more"
                  icon="plus"
                  onClick={() => addLabtesting()}
                />
              </div>
              {/* General and Lifestyle Advice */}
              <div className="presc_form_container-title">
                <Heading2
                  text="General and Lifestyle Advice"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              {advise.map((ele, index) => (
                <div style={{ position: "relative" }}>
                  <div className="presc-input-container margin-bottom-all">
                    <TextInput
                      placeholder={"E.g. Eat fiber-rich foods"}
                      value={ele}
                      onChange={(e) => updateAdvise(e, index)}
                      type="text"
                      required={true}
                    />
                    {index !== 0 && (
                      <div
                        className="presc-input-inline-remove"
                        onClick={() => removeAdvise(index)}
                      >
                        <Body3 text={`Remove`} color="danger" weight={"semitBold"} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div style={{ paddingBottom: "0px", marginTop: "-10px" }}>
                <InlineButton
                  lable="Add more"
                  icon="plus"
                  onClick={() => addAdvise()}
                />
              </div>
              <div className="recommendation-container">
                <div
                  className="recommendation-check-container"
                  onClick={() => setRecommendProgram(!recommendProgram)}
                >
                  <div
                    className={`reco-check ${recommendProgram ? "reco-check-active" : ""
                      }`}
                  >
                    <SVGCheck />
                  </div>
                  <Body3
                    text="Recommend lifestyle plan"
                    color="legend2"
                    weight={"normal"}
                  />

                </div>
                {recommendProgram ? (
                  <div className="presc-input-container-wrapper">
                    <div
                      className={`presc-input-container margin-bottom-only-mobile`}
                    >
                      <DropDown
                        placeholder={"E.g. 3-months"}
                        selected={planDuration}
                        onSelect={(e) => setPlanDuration(e)}
                        type="text"
                        required={true}
                        icon="degree"
                        dropDownOptions={planDurations}
                      />
                    </div>
                    <div className="presc-input-container">
                      <DropDown
                        placeholder={"E.g. PCOS Care 3 - Months"}
                        selected={plan}
                        onSelect={(e) => setPlan(e)}
                        type="text"
                        required={true}
                        icon="degree"
                        dropDownOptions={plans}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {/* Follow Up */}
              <div className="presc_form_container-title">
                <Heading2 text="Follow Up" weight="semitBold" color="oxfordBlue" />
              </div>
              <div>
                <Body3
                  text="Recommend follow up in"
                  color="legend2"
                  weight={"normal"}
                />

                <div className="presc-input-container-wrapper">
                  <div
                    className={`presc-input-container margin-bottom-only-mobile`}
                  >
                    <DropDown
                      placeholder={"E.g. 3"}
                      selected={followUp}
                      onSelect={(e) => setFollowUp(e)}
                      type="text"
                      required={true}
                      icon="degree"
                      dropDownOptions={followUpNumbers}
                    />
                  </div>
                  <div className="presc-input-container">
                    <DropDown
                      placeholder={"E.g. Months"}
                      selected={followUpType}
                      onSelect={(e) => setFollowUpTypes(e)}
                      type="text"
                      required={true}
                      icon="degree"
                      dropDownOptions={followUpTypes}
                    />
                  </div>
                </div>
              </div>

              {/* ------------------ */}
              <div
                style={{
                  // marginTop: "60px",
                  // paddingBottom: "40px",
                  paddingTop: "60px",
                  width: "200px",
                }}
              >
                <PrimaryButton
                  onClick={() => handleSubmit()}
                  lable="Submit"
                  disabled={fetching}
                />
              </div>
              <div style={{ marginTop: "80px" }}></div>
            </div>
          </div>

        </Content>
      </Layout>
    </Layout>
  );
};

export default NewPrescriptionForm;
