import { Layout, Card, Row, Col, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/common/navbar/Navbar';
import HeaderWithProfile from "../../components/headerWithProfile";
import './support.css';
import axios from "axios";
import { ROOT_URL } from "../../config";
import { setExpert } from "../../stores/actions/expert";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { expertDataSelector } from "../../stores/selectors/expert";
import Swal from "sweetalert2";


const Support = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [expertData, setExpertData] = useState({})
    const _expertData = useSelector((state) => expertDataSelector(state));
    const [reRender, setReRender] = useState(true);
    const [onboarded, setOnboarded] = useState(null);
    const [verified, setVerified] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const getData = async () => {
            const resp = await axios.get(ROOT_URL + "/profile", {
                withCredentials: true,
                credentials: "include",
                params: null,
            });
            if (resp && resp.data && resp.data.responseData)
                dispatch(setExpert(resp.data.responseData[0]));
        };
        getData();
    }, []);

    useEffect(() => {
        if (_expertData && _expertData.name) {
            setExpertData(_expertData)
        }
        setReRender(!reRender)
        setOnboarded(_expertData.onboarded)
        setVerified(_expertData.verified)
    }, [_expertData])

    useEffect(() => {
        if (verified === 0) {
            history.push("/register");
        } else if (onboarded === 0) {
            history.push("/profile");
        }
    }, [onboarded, verified])

    const successAlert = async (message, action) => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: message,
            showConfirmButton: false,
            timer: 2500,
        });
        const resp = await axios.post(
            ROOT_URL + "/alerts",
            {
                name: expertData.name,
                mobileNumber: expertData.calling_number,
                alertContext: action,
            },
            {
                withCredentials: true,
                credentials: "include",
                params: null,
            }
        );
    };

    const submitBug = async () => {
        if (message === '') {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please enter a message",
                showConfirmButton: false,
                timer: 2500,
            });
        } else {
            const resp = await axios.post(
                ROOT_URL + "/bugReport",
                {
                    name: expertData.name,
                    mobileNumber: expertData.calling_number,
                    message: message,
                },
                {
                    withCredentials: true,
                    credentials: "include",
                    params: null,
                }
            ).then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Bug reported successfully",
                        showConfirmButton: false,
                        timer: 2500,
                    });

                    setMessage('')
                }
            }).catch((err) => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Error in reporting bug",
                    showConfirmButton: false,
                    timer: 2500,
                });
            })
        }
    }


    return (
        <Layout style={{ backgroundColor: "#fff" }}>
            <Navbar defaultIndex={5} />

            <Layout>
                <Content>
                    <HeaderWithProfile title={"Support"} />

                    <div className='overview'>
                        <span className='quick-action-text'>Quick Action</span>

                        <div className='quick-action-buttons'>
                            <div
                                className='quick-action-button'
                                onClick={() =>
                                    successAlert("Our support team will get back to you", "Brochures")
                                }
                            >
                                <img src="/images/book-open.svg" alt="book-open" />
                                <span className='quick-action-button-text'>Request Brochures</span>
                                <img className='right-arrow-icon' src="/images/right-arrow.svg" alt="right-arrow" />
                            </div>
                            <div
                                className='quick-action-button'
                                onClick={() =>
                                    successAlert(
                                        "Our support team will get back to you",
                                        "Prescription Pad"
                                    )
                                }
                            >
                                <img src="/images/noun-prescription.svg" alt="book-open" />
                                <span className='quick-action-button-text'>Request Prescription Pad</span>
                                <img className='right-arrow-icon' src="/images/right-arrow.svg" alt="right-arrow" />
                            </div>
                            <div
                                className='quick-action-button'
                                onClick={() =>
                                    successAlert("Our support team will get back to you", "Callback")
                                }
                            >
                                <img src="/images/phone-call.svg" alt="book-open" />
                                <span className='quick-action-button-text'>Request a Callback</span>
                                <img className='right-arrow-icon' src="/images/right-arrow.svg" alt="right-arrow" />
                            </div>
                        </div>
                    </div>

                    <div className='overview'>
                        <span className='quick-action-text'>Report a Bug</span>

                        <div style={{ marginTop: "16px" }}>
                            <div>
                                <textarea
                                    className='support-text-area'
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                >
                                </textarea>
                            </div>
                            <div className='report-bug-btn-wrapper'>
                                <Button className='report-bug-btn' onClick={() => submitBug()}><span className='report-bug-btn-text'>Submit</span></Button>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Support;