import { ReactComponent as SVGUviLogoLight } from "../../elements/svg/uvi.svg";
import { ReactComponent as SVGUviLogo } from "../../elements/svg/uvidark.svg";

const UviLogo = ({ isLight, size = "54px" }) => {
  if (isLight) {
    return (
      <div style={{ width: size, height: size }}>
        <SVGUviLogoLight />
      </div>
    );
  } else {
    return (
      <div style={{ width: size, height: size }}>
        <SVGUviLogo />
      </div>
    );
  }
};

export default UviLogo;
