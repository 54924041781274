import { Body2,Body3 } from "../text";
import { getIcon } from "./inputUtil";
import "./Input.css";

const TextInput = ({
  lable,
  value,
  placeholder,
  onChange,
  type = "text",
  required = false,
  icon,
  prefix,
  disabled,
}) => {
  return (
    <div>
      <div>
        {lable && (
          <div className="text_input-lable">
            {icon ? (
              <div className="text_input-svg">{getIcon(icon)}</div>
            ) : null}

            <Body3
              text={required ? lable + "*" : lable}
              color="legend2"
              weight={"normal"}
            />
          </div>
        )}
      </div>
      <div style={{ position: "relative" }}>
        {prefix ? (
          <div className="text_input-prefix">
            <Body2 text={prefix} color="oxfordBlue" weight={"lightBold"} />
          </div>
        ) : null}
        <input
          className="text_input"
          style={prefix ? { paddingLeft: "84px" } : {}}
          type={type}
          placeholder={placeholder || ""}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TextInput;
