import React, { useEffect, useState, Link } from "react";
import moment from "moment";
import axios from "axios";
import { ROOT_URL } from "../../../config";
import "./ViewPrescriptions.css";
import { Heading2, Heading3, Body1 } from "../../../components/elements/text";
import InlineButton from "../../../components/elements/buttons/InlineButton";
import PlanNavbar from "../../../components/elements/navbar/planNavbar";

const ViewPrescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const resp = await axios.get(ROOT_URL + "/prescription", {
        withCredentials: true,
        credentials: "include",
        params: null,
      });
      if (resp && resp.data && resp.data.responseData)
        setPrescriptions(resp.data.responseData);
    };
    getData();
  }, []);

  
  return (
    <>
      <PlanNavbar />
      <div className="view_prescriptions-container">
        <Heading2
          text="Prescription History"
          weight="semitBold"
          color="oxfordBlue"
        />
        <div className="view_prescriptions-table">
          <div className="view_prescriptions-table_header">
            <div>Action</div>
            <div>Name</div>
            <div>whatsapp_number</div>
            <div>diagnosis</div>
            <div>last_updated</div>
            <div>followup_date</div>
          </div>
          {prescriptions.length > 0
            ? prescriptions.map((ele, index) => (
              <>
                {index !== 0 ? <div style={{ marginTop: "24px" }} /> : null}
                <div
                  key={index + ele.name}
                  className="view_prescriptions-table_row"
                >
                  <div className="view_prescriptions-table_row_element-wrap">
                    <div className="view_prescriptions-table_row_element">
                      <div className="view_prescriptions-table_row_lable">
                        <Body1
                          color="legend"
                          text={"Patient Name:  "}
                          weight="lightBold"
                        />
                      </div>

                      <Body1 text={ele.name} weight="lightBold" />
                    </div>
                    <div className="view_prescriptions-table_row_element">
                      <div className="view_prescriptions-table_row_lable">
                        <Body1
                          color="legend"
                          text={"WhatsApp:  "}
                          weight="lightBold"
                        />
                      </div>

                      <Body1 text={ele.whatsapp_number} weight="lightBold" />
                    </div>
                    <div className="view_prescriptions-table_row_element">
                      <div className="view_prescriptions-table_row_lable">
                        <Body1
                          color="legend"
                          text={"Diagnosis:  "}
                          weight="lightBold"
                        />
                      </div>

                      <Body1 text={ele.diagnosis} weight="lightBold" />
                    </div>
                  </div>
                  <div className="view_prescriptions-table_row_element-wrap">
                    <div className="view_prescriptions-table_row_element">
                      <div className="view_prescriptions-table_row_lable">
                        <Body1
                          color="legend"
                          text={"Created on:  "}
                          weight="lightBold"
                        />
                      </div>

                      <Body1
                        text={moment(ele.last_updated).format("lll")}
                        weight="lightBold"
                      />
                    </div>
                    <div className="view_prescriptions-table_row_element">
                      <div className="view_prescriptions-table_row_lable">
                        <Body1
                          color="legend"
                          text={"Follow up on:  "}
                          weight="lightBold"
                        />
                      </div>

                      <Body1
                        text={moment(ele.followup_date).format("lll")}
                        weight="lightBold"
                      />
                    </div>
                    <div className="view_prescriptions-table_row_btn">
                      <a
                        href={ele.prescription_url}
                        to={ele.prescription_url}
                        download
                        target={"_blank"}
                      >
                        <InlineButton
                          onClick={() => { }}
                          lable="Download prescription"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </>
            ))
            : <div className="view_prescriptions-empty"><Body1 text={"Please create prescription to see prescription history"} color="legend2" /></div>}
        </div>
      </div>
    </>
  );
};

export default ViewPrescriptions;
