import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { setExpert } from "../../stores/actions/expert";

import { ROOT_URL } from "../../config";
import Swal from "sweetalert2";
import { expertDataSelector } from "../../stores/selectors/expert";
import EditProfile from "../../components/editProfileHeader";
import TextInput from "../../components/elements/input/textInput";
import DropDown from "../../components/elements/input/dropDown";
import { Heading2, Body3, Body4 } from "../../components/elements/text";
import PrimaryButton from "../../components/elements/buttons/primaryButton";
import InlineButton from "../../components/elements/buttons/InlineButton";
import FileInput from "../../components/elements/input/fileInput";
import { ReactComponent as SVGCheck } from "../../components/elements/svg/check-plain.svg";
import { Layout, Card, Row, Col, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Navbar from '../../components/common/navbar/Navbar';
import HeaderWithProfile from "../../components/headerWithProfile";

import "./Profile.css";
import { useEffect } from "react";

const degreeArr = [
  "MBBS",
  "MD",
  "MS",
  "DNB",
  "DM",
  "M.Ch",
  "BDS",
  "BAMS",
  "BUMS",
  "BHMS",
  "BYNS",
];
const gender = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
];
const years = [
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
];
const specialisations = [
  "Obstetrics and Gynecology",
  "Dermatology",
  "Internal Medicine",
  "Urology",
  "Endocrinology",
  "Orthopedics",
  "Pediatrics",
  "Radiology",
  "General Surgery",
  "Ophthalmology",
  "ENT",
  "Pathology",
  "Venerology",
];
const genders = ["Male", "Female", "Transgender", "Non-binary"];

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const expertData = useSelector((state) => expertDataSelector(state));

  const [phone, setPhone] = useState(expertData.calling_number || "");
  const [name, setName] = useState(expertData.name || "");
  const [profileURL, setProofileUrl] = useState(
    expertData.profile_picture || ""
  );
  const [whatsApp, setWhatsApp] = useState(expertData.whatsapp_number || "");
  const [gender, setGender] = useState(expertData.gender || "");
  const [email, setEmail] = useState(expertData.email || "");
  const [regNo, setRegNo] = useState(expertData.rmp_number || "");
  const [clinic, setClinic] = useState(expertData.clinic_name || "");
  const [address, setAddress] = useState(expertData.clinic_address || "");
  const [pincode, setPincode] = useState(expertData.clinic_pincode || "");
  const [education, setEducation] = useState(
    expertData.degree_details || [
      {
        degree_name: "",
        graduation_year: "",
        certificate_url: "",
        specialisation: "",
      },
    ]
  );
  const [specialisation, setSpecialisation] = useState(
    expertData.specialisation || ""
  );

  const [reRender, setReRender] = useState(false);
  const [sameAsCalling, setSameAsCalling] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);

  useEffect(() => {
    if (!whatsApp && !expertData.whatsapp_number) {
      if (sameAsCalling) {
        setWhatsApp(phone);
      } else {
        setWhatsApp("");
      }
    }
  }, [phone, sameAsCalling]);

  useEffect(() => {
    if (!expertData || !expertData.name) {
      history.push('/')
    }
  }, [expertData])

  const addEducation = () => {
    const data = {
      degree_name: "",
      graduation_year: "",
      certificate_url: "",
      specialisation: "",
    };
    const current = education;
    current.push(data);
    setEducation(current);
    setReRender(!reRender);
  };
  const removeEducation = (index) => {
    const current = education;
    current.splice(index, 1);
    setEducation(current);
    setReRender(!reRender);
  };
  const editEducation = (index, field, value) => {
    const current = education;
    current[index][field] = value;
    setEducation(current);
    setReRender(!reRender);
  };
  const validateForm = () => {
    if (!name || name.length < 3) {
      Swal.fire("Please enter your name");
      return false;
    }
    if (!phone || phone.length !== 12) {
      Swal.fire("Please enter your 12 digit phone number");
      return false;
    }
    if (!gender) {
      Swal.fire("Please select your gender");
      return false;
    }
    if (!whatsApp) {
      Swal.fire("Please enter your WhatsApp number");
      return false;
    }
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    if (!email || !validateEmail(email)) {
      Swal.fire("Please enter a valid email address");
      return false;
    }
    if (!specialisation) {
      Swal.fire("Please enter your specialisation");
      return false;
    }

    if (!clinic) {
      Swal.fire("Please enter your clinic name");
      return false;
    }
    if (!address) {
      Swal.fire("Please enter your clinic address");
      return false;
    }
    if (education[0].degree_name === "") {
      Swal.fire("Please add at least one education qualification");
      return false;
    }

    return true;
  };
  const Submit = () => {
    const validate = validateForm();
    if (validate === false) {
      return null;
    }
    let send_onboarding_message = false;
    if (expertData.onboarded === 0) {
      send_onboarding_message = true;
    }
    const payload = {
      calling_number: phone,
      name: name,
      whatsapp_number: whatsApp,
      email: email,
      clinic_name: clinic,
      clinic_address: address,
      clinic_pincode: pincode,
      degree_details: education,
      doctor_id: expertData.doctor_id,
      gender: gender,
      onboarded: 1,
      profile_picture: profileURL,
      rmp_number: regNo,
      signature_style: null,
      specialisation: specialisation,
      verified: 1,
      send_onboarding_message
    };
    const makeCall = async () => {
      const resp = await axios.post(ROOT_URL + "/profile", payload, {
        withCredentials: true,
        credentials: "include",
      });
      if (
        resp &&
        resp.data &&
        resp.data.responseStatus &&
        resp.data.responseStatus.message === "Profile Data Updated"
      ) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Profile Updated",
          showConfirmButton: false,
          timer: 2500,
        });
        dispatch(setExpert(resp.data.responseData));
        history.push('/')
      }
    };
    makeCall();
  };

  const handleFileUpload = async (e, isEducation, index) => {
    console.log("called");
    setImageUpload(true);
    const files = e.target.files;
    const fileSize = files[0].size;
    const limitInMB = 5;

    if (fileSize > limitInMB * 1000000) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Please upload file less than 5 MB",
        showConfirmButton: false,
        timer: 2500,
      });
      setImageUpload(false);
      return;
    }

    const response = await axios.get(
      `${ROOT_URL}/presignedUrl?queryVal=${files[0].type}`,
      {
        withCredentials: true,
        credentials: "include",
      }
    );
    if (response && response.data && response.data.responseData) {
      await axios
        .put(response.data.responseData.url, files[0], {
          headers: {
            "Content-Type": files[0].type,
          },
        })
        .then((val) => {
          const uploadUrl = `https://uvi-content-store.s3.amazonaws.com/${response.data.responseData.key}`;
          if (isEducation) {
            editEducation(index, "certificate_url", uploadUrl);
            setImageUpload(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Degree certificate uploaded sucessfully",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            setProofileUrl(uploadUrl);
            setImageUpload(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Profile image uploaded sucessfully",
              showConfirmButton: false,
              timer: 2500,
            });
          }
        })
        .catch((err) => {
          setImageUpload(false);
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Could not upload file, please try again",
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } else {
      setImageUpload(false);
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Could not upload file, please try again",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <Layout>
      <Navbar />

      <Layout>
        <Content>
          <HeaderWithProfile title={"Profile Details"} />
          <div className="">
          {/* <EditProfile
            expertData={expertData}
            handleFileUpload={(e) => handleFileUpload(e, false, null)}
            profileURL={profileURL}
          /> */}
          <div className="profile-form-container">
            <div className="profile_form_container-title">
              <Heading2
                text="Basic Details"
                weight="semitBold"
                color="oxfordBlue"
              />
            </div>
            <div className="profile-input-container-wrapper">
              <div className="profile-input-container">
                <TextInput
                  lable={"Your Full Name"}
                  placeholder={"E.g. Deepa Sharma"}
                  value={name}
                  onChange={(e) => setName(e)}
                  type="text"
                  required={true}
                  prefix="Dr."
                />
              </div>
              <div className="profile-input-container">
                <DropDown
                  lable={"Gender"}
                  placeholder={"E.g. Female"}
                  selected={gender}
                  onSelect={(e) => setGender(e)}
                  type="text"
                  required={true}
                  dropDownOptions={genders}
                />
              </div>
              <div className="profile-input-container">
                <TextInput
                  lable={"Phone number"}
                  disabled={expertData.calling_number ? true : false}
                  placeholder={"E.g. 9844332266"}
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  type="tel"
                  required={true}
                  icon="phone"
                />
              </div>
            </div>

            <div className="profile-input-container-wrapper">
              <div className="profile-input-container">
                <TextInput
                  lable={"WhatsApp number"}
                  placeholder={"E.g. 9844332266"}
                  value={whatsApp}
                  onChange={(e) => setWhatsApp(e)}
                  type="tel"
                  required={true}
                  icon="phone"
                />
                {!expertData || !expertData.whatsapp_number ? (
                  <div>
                    <div
                      className="whatsapp-check-container"
                      onClick={() => setSameAsCalling(!sameAsCalling)}
                    >
                      <Body4 text="Same as Calling Number" color="legend2" />
                      <div
                        className={`whatsapp-check ${sameAsCalling ? "whatsapp-check-active" : ""
                          }`}
                      >
                        <SVGCheck />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="profile-input-container">
                <TextInput
                  lable={"Email ID"}
                  placeholder={"E.g. deepasharma@gmail.com"}
                  value={email}
                  onChange={(e) => setEmail(e)}
                  type="text"
                  required={true}
                  icon="mail"
                />
              </div>
              <div className="profile-input-container">
                <DropDown
                  lable={"Specialisation"}
                  placeholder={"E.g. Obstetrics and Gynecology"}
                  selected={specialisation}
                  onSelect={(e) => setSpecialisation(e)}
                  type="text"
                  required={true}
                  icon="degree"
                  dropDownOptions={specialisations}
                />
              </div>
            </div>
            <div className="profile-input-container">
              <TextInput
                lable={"Medical Registration Number"}
                placeholder={"E.g. 89549"}
                value={regNo}
                onChange={(e) => setRegNo(e)}
                type="tel"
              />
            </div>

            {/* --------------------------------------------------*/}

            <div className="profile_form_container-title">
              <Heading2
                text="Clinic Details"
                weight="semitBold"
                color="oxfordBlue"
              />
            </div>
            <div className="profile-input-container-wrapper">
              <div className="profile-input-container">
                <TextInput
                  lable={"Clinic Name"}
                  placeholder={"E.g. Asha Clinic"}
                  value={clinic}
                  onChange={(e) => setClinic(e)}
                  type="text"
                  required={true}
                  icon="address"
                />
              </div>
              <div className="profile-input-container">
                <TextInput
                  lable={"Clinic Address"}
                  placeholder={"E.g. 281, 6th Block, 17th Cross Koramangala"}
                  value={address}
                  onChange={(e) => setAddress(e)}
                  type="text"
                  required={true}
                  icon="map"
                />
              </div>
              <div className="profile-input-container">
                <TextInput
                  lable={"Postal Pin Code"}
                  placeholder={"E.g. 570001"}
                  value={pincode}
                  onChange={(e) => setPincode(e)}
                  type="text"
                />
              </div>
            </div>

            {/* --------------------------------------------------*/}

            <div className="profile_form_container-title">
              <Heading2
                text="Education Details"
                weight="semitBold"
                color="oxfordBlue"
              />
            </div>
            {education.map((ele, index) => (
              <>
                {index !== 0 ? <div style={{ height: "32px" }} /> : null}
                <div>
                  <div className="profile_form_container-education_wrapper">
                    {index !== 0 ? (
                      <div
                        onClick={() => removeEducation(index)}
                        className="profile_form_container-education_delete"
                      >
                        <Body3
                          text={`Remove Qualification ${index + 1}`}
                          color="danger"
                          weight={"semitBold"}
                        />
                      </div>
                    ) : null}
                    <div className="profile-input-container">
                      <DropDown
                        lable={"Degree Qualification"}
                        placeholder={"E.g. MBBS"}
                        selected={ele.degree_name}
                        onSelect={(e) => editEducation(index, "degree_name", e)}
                        type="text"
                        required={true}
                        icon="degree"
                        dropDownOptions={degreeArr}
                      />
                    </div>
                    <div className="profile-input-container">
                      <DropDown
                        lable={"Year Of Qualification"}
                        placeholder={"E.g. 2001"}
                        selected={ele.graduation_year}
                        onSelect={(e) => editEducation(index, "graduation_year", e)}
                        type="text"
                        required={true}
                        icon="calender"
                        dropDownOptions={years}
                      />
                    </div>
                    <div className="profile-input-container">
                      <TextInput
                        lable={"Specialisation"}
                        placeholder={"Obstetrics & Gynaecology"}
                        value={ele.specialisation}
                        onChange={(e) => editEducation(index, "specialisation", e)}
                        type="text"
                      />
                    </div>
                    <FileInput
                      lable={
                        <p>
                          Upload Certificate{" "}
                          <span style={{ fontStyle: "italic" }}>(Optional)</span>
                        </p>
                      }
                      file={ele.certificate_url}
                      onChange={(e) => handleFileUpload(e, true, index)}
                      removeHandler={() =>
                        editEducation(index, "certificate_url", "")
                      }
                    />
                    {index !== 0 ? (
                      <div className="education_padding_bottom" />
                    ) : null}
                  </div>
                </div>
              </>
            ))}
            <div style={{ paddingTop: "8px", paddingBottom: "40px" }}>
              <InlineButton
                lable="Add education"
                icon="plus"
                onClick={() => addEducation()}
              />
            </div>

            <div
              style={{
                marginTop: "20px",
                paddingBottom: "40px",
                width: "200px",
                margin: "auto",
              }}
            >
              <PrimaryButton onClick={() => Submit()} lable="Submit" />
            </div>
          </div>
        </div>
        </Content>
      </Layout>
    </Layout>

  );
};

export default Profile;
