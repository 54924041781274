import { ReactComponent as SVGVerified } from "../../elements/svg/verified.svg";

const VerifiedIcon = ({ size = "20px" }) => {
  return (
    <div style={{ width: size, height: size }}>
      <SVGVerified />
    </div>
  );
};

export default VerifiedIcon;
