export const STATS = `[STATS]`;

export const STATS_GET = `${STATS} GET`;
export const STATS_SET = `${STATS} SET`;

export const getStats = (payload) => ({
    type: STATS_GET,
    payload,
});

export const setStats = (payload) => ({
    type: STATS_SET,
    payload,
});
