import "./text.css";

const colors = {
  primary: "#f5735a",
  oxfordBlue: "#373750",
  legend: "#525268",
  legend2: "#727382",
  muted: "#9ca3af",
  white: "#fff",
  danger: "#DF1F32",
  green: "#73BE9D",
  magentaDye:'#DC4A9A',
  text:"#10202D",
};

const weights = {
  light: "300",
  normal: "400",
  lightBold: "500",
  semitBold: "600",
  bold: "700",
  bolder: "800",
};

const paramStyles = ({ color, weight }) => {
  let resStyle = {};
  if (color) {
    const selectColor = colors[color];
    resStyle = { ...resStyle, color: selectColor };
  }
  if (weight) {
    const selectWeight = weights[weight];
    resStyle = { ...resStyle, fontWeight: selectWeight };
  }
  return resStyle;
};

const Heading1 = ({ text, color, weight }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="text-tx-h2" style={customeStyle}>
      {text}
    </span>
  );
};

const Heading2 = ({ text, color, weight, dynamic }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className={`heading2 ${dynamic ? "dy-h2" : ""}`} style={customeStyle}>
      {text}
    </span>
  );
};

const Heading3 = ({ text, color, weight, dynamic }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="heading3" style={customeStyle}>
      {text}
    </span>
  );
};

const Heading4 = ({ text, color, weight, dynamic }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="heading4" style={customeStyle}>
      {text}
    </span>
  );
};

const Heading5 = ({ text, color, weight, dynamic }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="heading5" style={customeStyle}>
      {text}
    </span>
  );
};



const Body1 = ({ text, color, weight }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="body1" style={customeStyle}>
      {text}
    </span>
  );
};

const Body2 = ({ text, color, weight, dynamic }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className={`body2 ${dynamic ? "dy-b2" : ""}`} style={customeStyle}>
      {text}
    </span>
  );
};

const Body3 = ({ text, color, weight }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="body3" style={customeStyle}>
      {text}
    </span>
  );
};

const Body4 = ({ text, color, weight }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="body4" style={customeStyle}>
      {text}
    </span>
  );
};

const Lable = ({ text, color, weight }) => {
  const customeStyle = paramStyles({ color, weight });
  return (
    <span className="lable" style={customeStyle}>
      {text}
    </span>
  );
};

export { Heading1, Heading2, Heading3, Heading4, Heading5, Body1, Body2, Body3, Body4, Lable };
