import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../../config";
import moment from "moment/moment";
import Swal from "sweetalert2";

import { expertDataSelector } from "../../../stores/selectors/expert";
import TextInput from "../../../components/elements/input/textInput";
import DropDown from "../../../components/elements/input/dropDown";
import { Heading2, Body2, Body3 } from "../../../components/elements/text";
import InlineButton from "../../../components/elements/buttons/InlineButton";
import PrimaryButton from "../../../components/elements/buttons/primaryButton";
import PlanNavbar from "../../../components/elements/navbar/planNavbar";
import Navbar from "../../../components/common/navbar/Navbar";
import HeaderWithProfile from "../../../components/headerWithProfile";
import { getPatientDetails } from "../../../stores/actions/patient";
import { patientDetailsSelector } from "../../../stores/selectors/patient";

import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from "antd/lib/layout/layout";

import "./updatePatient.css";

const followUpNumbers = Array.from(Array(31).keys());
followUpNumbers.shift();
const genders = ['Male', 'Female', 'Transgender', 'Non-binary'];

const UpdatePatient = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const patientId = props.match.params.patient_id;

  const [patientName, setPatientName] = useState("");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [callingNumber, setCallingNumber] = useState("");

  useEffect(() => {
    dispatch(getPatientDetails(patientId));
  }, [patientId]);

  const patientDetails = useSelector(patientDetailsSelector);

  useEffect(() => {
    if (patientDetails) {
      setPatientName(patientDetails.name);
      setAge(patientDetails.age);
      setHeight(patientDetails.height);
      setWeight(patientDetails.weight);
      setGender(patientDetails.gender);
      setWhatsappNumber(patientDetails.whatsapp_number?patientDetails.whatsapp_number.substring(2, 12):"");
      setCallingNumber(patientDetails.calling_number?patientDetails.calling_number.substring(2, 12):"");
    }
  }, [patientDetails]);


  const validateForm = () => {
    if (!patientName || patientName.length < 3) {
      Swal.fire("Please enter patient's name");
      return false;
    }
    if (!age) {
      Swal.fire("Please enter patient's age");
      return false;
    }
    if (!gender) {
      Swal.fire("Please enter patient's gender");
      return false;
    }
    if (!whatsappNumber || whatsappNumber.length !== 10) {
      Swal.fire("Please enter patient's 10 digit WhatsApp number");
      return false;
    }
    return true;
  };


  const handleSubmit = () => {

    validateForm()

    const payload = {
      patient_id: patientId,
      name: patientName,
      age: age,
      gender: gender,
      height: height,
      weight: weight,
      calling_number: "91" + callingNumber,
      whatsapp_number: "91" + whatsappNumber
    };
    const makeCall = async () => {
      const resp = await axios.put(ROOT_URL + "/updatePatient", payload, {
        withCredentials: true,
        credentials: "include",
      });
      if (
        resp &&
        resp.data &&
        resp.data.responseStatus &&
        resp.data.responseStatus.message === "Patient updated"
      ) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Patient updated",
          showConfirmButton: false,
          timer: 1500,
        });
        history.push("/patients");
      }
    };
    makeCall();
  };

  return (
    <Layout>
      <Navbar defaultIndex={4} />

      <Layout>
        <Content>
          <HeaderWithProfile title={"Update Patient"} />

          <div>
            <div className="profile-form-container">
              <div className="referral-title">
                <Heading2
                  text="Patient Details"
                  weight="semitBold"
                  color="oxfordBlue"
                />
              </div>
              <div className="prescription-input-container-wrapper">
                <div className="prescription-input-container margin-bottom-all">
                  <TextInput
                    lable={"Patient's Name"}
                    placeholder={"E.g. Puja Sharma"}
                    value={patientName}
                    onChange={(e) => setPatientName(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="prescription-input-container margin-bottom-all">
                  <TextInput
                    lable={"Patient's Age"}
                    placeholder={"E.g. 29"}
                    value={age}
                    onChange={(e) => setAge(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="prescription-input-container margin-bottom-all">
                  <DropDown
                    lable={"Gender"}
                    placeholder={"E.g. Female"}
                    selected={gender}
                    onSelect={(e) => setGender(e)}
                    type="text"
                    required={true}
                    dropDownOptions={genders}
                  />
                </div>
                <div className="prescription-input-container margin-bottom-all">
                  <TextInput
                    lable={"Patient's Height"}
                    placeholder={"E.g. 29"}
                    value={height}
                    onChange={(e) => setHeight(e)}
                    type="text"
                    required={true}
                  />
                </div>
              </div>
              <div className="prescription-input-container-wrapper">
                <div className="prescription-input-container margin-bottom-all">
                  <TextInput
                    lable={"Patient's Weight"}
                    placeholder={"E.g. 60"}
                    value={weight}
                    onChange={(e) => setWeight(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="prescription-input-container">
                  <TextInput
                    lable={"Patient's WhatsApp Number"}
                    placeholder={"E.g. 9845456775"}
                    value={whatsappNumber}
                    onChange={(e) => setWhatsappNumber(e)}
                    type="text"
                    required={true}
                  />
                </div>
                <div className="prescription-input-container">
                  <TextInput
                    lable={"Patient's Calling Number"}
                    placeholder={"E.g. 9845456775"}
                    value={callingNumber}
                    onChange={(e) => setCallingNumber(e)}
                    type="text"
                    required={true}
                  />
                </div>
              </div>

              {/* ------------------ */}
              <div
                style={{
                  // marginTop: "60px",
                  // paddingBottom: "40px",
                  paddingTop: "60px",
                  width: "157px",
                  height: "48px"
                }}
              >
                <PrimaryButton onClick={() => handleSubmit()} lable="Update" />
              </div>
              <div style={{ marginTop: "80px" }}></div>
            </div>
          </div>

        </Content>
      </Layout>
    </Layout>
  );
};

export default UpdatePatient;
