export const PATIENTS = `[PATIENTS]`;
export const PATIENT = `[PATIENT]`;

export const PATIENTS_GET = `${PATIENTS} GET`;
export const PATIENTS_SET = `${PATIENTS} SET`;

export const PATIENT_GET = `${PATIENT} GET`;
export const PATIENT_SET = `${PATIENT} SET`;

export const getPatients = (payload) => ({
    type: PATIENTS_GET,
    payload,
});

export const setPatients = (payload) => ({
    type: PATIENTS_SET,
    payload,
});

export const getPatientDetails = (payload) => ({
    type: PATIENT_GET,
    payload,
});

export const setPatientDetails = (payload) => ({
    type: PATIENT_SET,
    payload,
});