import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOtp, loginCheck, verifyOtp } from "../../stores/actions/login";
import { otpSelector, authSelector } from "../../stores/selectors/auth";
import axios from "axios";
import { ROOT_URL } from "../../config";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [mobileNumber, setMobileNumber] = useState("");
  const [isOtpGenerated, setIsOtpGenerated] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 1;

      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex + 1;
      if (next <= 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const otpStatus = useSelector((state) => otpSelector(state));
  let isAuthenticated = useSelector(
    (state) => authSelector(state),
    shallowEqual
  );

  // useEffect(() => {
  //   if (otpStatus) {
  //     setIsOtpGenerated(true);
  //   }
  // }, [otpStatus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isOtpGenerated) {
      // dispatch(getOtp({ mobileNumber: `91${mobileNumber}` }));
      const getData = async () => {
        const resp = await axios.post(ROOT_URL + "/generateOTP",
          { mobileNumber: `91${mobileNumber}` },
          {
            withCredentials: true,
            credentials: "include",
            params: null,
          });
        if (resp && resp.data && resp.data.responseData)
          setIsOtpGenerated(true);
        // dispatch(setExpert(resp.data.responseData[0]));
      };
      getData();
    } else {
      const otp = otp1 + otp2 + otp3 + otp4;
      dispatch(
        verifyOtp({ mobileNumber: `91${mobileNumber}`, otp: parseInt(otp) })
      );
      // console.log(mobileNumber,parseInt(otp,10))
    }
  };

  useEffect(() => {
    dispatch(loginCheck());
    if (isAuthenticated) {
      history.push("/");
    } else {
      history.push("/login");
    }
  }, [isAuthenticated, history]);

  return (
    <div className="flex">
      <div className="w-full h-screen md:w-1/4">
        <div className="flex items-center justify-center h-screen">
          <div className="px-6">
            <h3 className="text-2xl">Sign In</h3>
            <p className="py-2 my-1 text-xs">
              Enter your email address and password to access <br />
              Uvi Health admin dashboard
            </p>
            <form className="my-10">
              <div
                className="mb-6"
                style={{ display: `${isOtpGenerated ? "none" : "block"}` }}
              >
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium"
                >
                  Registered Number
                </label>
                <input
                  type="phone"
                  id="phone"
                  className="border border-gray-300 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 "
                  placeholder="Enter 10 digit mobile number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>

              <div
                id="otp"
                className="flex flex-row justify-center px-2 my-5 text-center"
                style={{ display: `${isOtpGenerated ? "block" : "none"}` }}
              >
                <input
                  className="w-10 h-10 m-2 text-center border rounded form-control"
                  type="text"
                  id="first"
                  maxLength="1"
                  value={otp1}
                  onChange={(e) => setOtp1(e.target.value)}
                  tabIndex="1"
                  onKeyUp={(e) => inputfocus(e)}
                />

                <input
                  className="w-10 h-10 m-2 text-center border rounded form-control"
                  type="text"
                  id="second"
                  maxLength="1"
                  value={otp2}
                  onChange={(e) => setOtp2(e.target.value)}
                  tabIndex="2"
                  onKeyUp={(e) => inputfocus(e)}
                />

                <input
                  className="w-10 h-10 m-2 text-center border rounded form-control"
                  type="text"
                  id="third"
                  maxLength="1"
                  value={otp3}
                  onChange={(e) => setOtp3(e.target.value)}
                  tabIndex="3"
                  onKeyUp={(e) => inputfocus(e)}
                />

                <input
                  className="w-10 h-10 m-2 text-center border rounded form-control"
                  type="text"
                  id="fourth"
                  maxLength="1"
                  value={otp4}
                  onChange={(e) => setOtp4(e.target.value)}
                  tabIndex="4"
                  onKeyUp={(e) => inputfocus(e)}
                />
              </div>
              <button
                type="submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="text-white bg-slate-700 font-medium rounded-sm text-sm w-full px-5 py-2.5 text-center "
              >
                {isOtpGenerated ? (
                  <div>Verify OTP</div>
                ) : (
                  <div>Generate OTP</div>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* <div className="">
                <footer className="conatiner">
                    <p className="text-sm">
                        Don't have an account?{" "}
                        <b>Please contact admin.</b>
                    </p>
                </footer>
            </div> */}
      <div className="w-0 h-screen md:w-3/4">
        <img
          className="h-screen"
          style={{ objectFit: "cover" }}
          src={process.env.PUBLIC_URL + "/images/bg-auth.png"}
        />
      </div>
    </div>
  );
};

export default Login;
