import { Table, Layout, Card, Row, Col, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect } from 'react';
import Navbar from '../../../components/common/navbar/Navbar';
import HeaderWithProfile from "../../../components/headerWithProfile";
import './patient.css';
import { EditOutlined } from '@ant-design/icons';
import { Heading2, Heading3, Heading4, Heading5 } from "../../../components/elements/text";
import { getPatients } from '../../../stores/actions/patient';
import { patientsListSelector } from '../../../stores/selectors/patient';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Space } from 'antd';
import { useHistory } from 'react-router-dom';

const { RangePicker } = DatePicker;

const Patient = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getPatients());
    }, [dispatch]);

    const patients = useSelector((state) => patientsListSelector(state));

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            responsive: ['lg'],
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            responsive: ['lg'],
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            responsive: ['lg'],
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (patient_id) => <a onClick={() => history.push(`/updatePatient/${patient_id}`)} style={{ color: "#DC4A9A" }}>Edit <span><EditOutlined style={{ color: "#DC4A9A", position: "absolute", bottom: "36.36%" }} /></span></a>,
        },
    ];

    const data = patients.length !== 0 ? patients.map((patient, index) => ({
        key: index,
        name: patient.name,
        age: patient.age,
        gender: patient.gender,
        phoneNumber: patient.calling_number ? patient.calling_number : patient.whatsapp_number ? patient.whatsapp_number : "NA",
        action: patient.patient_id,
    })) : [];


    return (
        <Layout style={{ backgroundColor: "#fff" }}>
            <Navbar defaultIndex={2} />

            <Layout>
                <Content>
                    <HeaderWithProfile title={"All Patients"} />

                    <div className='overview'>
                        <Row style={{ justifyContent: "space-between", margin: "auto" }}>
                            <Col span={12}>
                                <Heading3 weight="bold" color="oxfordBlue" text="Overview" />
                                {/* <h1 style={{fontFamily: 'Montserrat', fontSize: "20px", fontWeight: 600, color: "#10202D"}}>Overview</h1> */}
                            </Col>
                            <Col>
                                {/* <Button className='add-patient-btn'>+ Add Patient</Button> */}
                            </Col>
                        </Row>

                        {/* <Space direction="vertical" size={12}>
                            <RangePicker />

                        </Space> */}
                        <div className='patient-overview-card-container'>
                            <Card
                                className="patient-overview-card"
                                width={100}
                                style={{ borderRadius: "12px", backgroundColor: "rgba(255, 241, 230, 0.5)" }}
                            >
                                <div className="overview-card-text-container" >
                                    <div className="overview-card-text">Total Patients</div>
                                    <div class="tooltip">
                                        <img src="/images/info-icon.svg" alt="info-icon" />
                                        <span class="tooltiptext">Number of Referred and Prescribed patients</span>
                                    </div>
                                </div>
                                <div className="home-overview">
                                    <img className='icon' src="/images/dollar-circle.svg" alt="Refer Patient" />
                                    <span className="overview-card-number" style={{ color: "#A0644A" }}>{patients.length}</span>
                                </div>

                            </Card>
                        </div>
                    </div>
                    <div className="patient-table">
                        <Table columns={columns} dataSource={data} />;
                    </div>
                </Content>
            </Layout>


        </Layout>
    );
};

export default Patient;