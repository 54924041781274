import { SUCCESSFUL_REFERRAL_SET } from "../../actions/successfulReferral"

const initState = []
export const successfulReferralReducer = (state=initState,action) =>{
    switch(action.type){
        case SUCCESSFUL_REFERRAL_SET:
            return {...state,successfulReferralList:action.payload}
        default: return state
    }
}
