import { ReactComponent as SVGDegree } from "../svg/degree.svg";
import { ReactComponent as SVGPhone } from "../svg/phone.svg";
import { ReactComponent as SVGMail } from "../svg/mail.svg";
import { ReactComponent as SVGAddress } from "../svg/address.svg";
import { ReactComponent as SVGMapPin } from "../svg/map-pin.svg";
import { ReactComponent as SVGCalendar } from "../svg/calendar.svg";
import { ReactComponent as SVGChevDown } from "../svg/chevron-down.svg";
import { ReactComponent as SVGCheck } from "../svg/check.svg";

function getIcon(icon) {
  switch (icon) {
    case "degree":
      return <SVGDegree />;
    case "phone":
      return <SVGPhone />;
    case "mail":
      return <SVGMail />;
    case "address":
      return <SVGAddress />;
    case "map":
      return <SVGMapPin />;
    case "calender":
      return <SVGCalendar />;
    case "chevDown":
      return <SVGChevDown />;
    case "check":
      return <SVGCheck />;
    default:
      break;
  }
}

export { getIcon };
