import { apiRequest, API_SUCCESS, API_ERROR } from "../../../actions/api";
import Alert from "sweetalert2";
import {
  PRESCRIPTION,
  PRESCRIPTION_CREATE,
  PRESCRIPTIONS,
  PRESCRIPTIONS_GET,
  setPrescriptions
} from "../../../actions/prescription/";
import { ROOT_URL } from "../../../../config/";

export const prescriptionMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case PRESCRIPTION_CREATE:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/createPrescription`,
            method: "post",
            feature: PRESCRIPTION,
          })
        );
        break;

      case PRESCRIPTIONS_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/prescription`,
            method: "get",
            feature: PRESCRIPTIONS,
          })
        );
        break;

      case `${PRESCRIPTIONS} ${API_SUCCESS}`:
        dispatch(setPrescriptions(action.payload));
        break;
        
      case `${PRESCRIPTIONS} ${API_ERROR}`:
          Alert.fire({
            icon: "error",
            title: "Error",
            showConfirmButton: false,
            timer: 1500,
          });
          break;

      case `${PRESCRIPTION} ${API_SUCCESS}`:
        Alert.fire({
          icon: "success",
          title: "Created",
          showConfirmButton: false,
          timer: 1500,
        });

        break;
      case `${PRESCRIPTION} ${API_ERROR}`:
        Alert.fire({
          icon: "error",
          title: "Error",
          showConfirmButton: false,
          timer: 1500,
        });
        break;
      default:
        break;
    }
  };
