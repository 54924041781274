import { STATS_SET } from "../../actions/home"

const initState = []
export const homeReducer = (state=initState,action) =>{
    switch(action.type){
        case STATS_SET:
            return {...state,stats:action.payload}
        default: return state
    }
}
