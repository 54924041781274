import { EXPERT_SET } from "../../actions/expert/";

const initState = []
export const expertReducer = (state=initState,action) =>{
    switch(action.type){
        case EXPERT_SET:
            return {...state,expertData:action.payload}
        default: return state
    }
}
