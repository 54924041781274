import { OTP_SET, AUTH_SET } from "../../actions/login/"

const initState = []
export const authReducer = (state=initState,action) =>{
    switch(action.type){
        case OTP_SET:
            return {...state,otp:action.payload}
        case AUTH_SET:
            return {...state,auth:action.payload}
        default: return state
    }
}
