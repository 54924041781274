import { apiRequest, API_SUCCESS, API_ERROR } from "../../../actions/api";
import Alert from "sweetalert2";
import { ROOT_URL } from "../../../../config/";
import { SUCCESSFUL_REFERRAL, SUCCESSFUL_REFERRAL_GET, setSuccessfulReferral } from "../../../actions/successfulReferral";

export const successfulReferralMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case SUCCESSFUL_REFERRAL_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/successfulReferrals`,
            method: "get",
            feature: SUCCESSFUL_REFERRAL,
          })
        );
        break;

      case `${SUCCESSFUL_REFERRAL} ${API_SUCCESS}`:
        dispatch(setSuccessfulReferral(action.payload));
        break;

      case `${SUCCESSFUL_REFERRAL} ${API_ERROR}`:
        Alert.fire({
          icon: "error",
          title: "Error",
          showConfirmButton: false,
          timer: 1500,
        });
        break;
      default:
        break;
    }
  };