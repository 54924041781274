import {PRESCRIPTIONS_SET} from '../../actions/prescription';

const initState = []
export const prescriptionReducer = (state=initState,action) =>{
    switch(action.type){
        case PRESCRIPTIONS_SET:
            return {...state, prescriptions:action.payload}
        default: return state
    }
}
