import PulseLoader from "react-spinners/PulseLoader";

import { ReactComponent as SVGPad } from "../../svg/pad.svg";
import { ReactComponent as SVGPhone } from "../../svg/phone-call.svg";
import { ReactComponent as SVGRightArrow } from "../../svg/chevron-right.svg";
import { ReactComponent as SVGBook } from "../../svg/book-open.svg";
import { ReactComponent as SVGUpload } from "../../svg/upload.svg";

import { Body1 } from "../../text/index";

import "./PrimaryButton.css";

const PrimaryButton = ({ icon, lable, bgScheme, key, onClick, inline, disabled, bgColor }) => {
  function getIcon(icon) {
    if (icon === "book") {
      return <SVGBook />;
    } else if (icon === "pad") {
      return <SVGPad />;
    } else if (icon === "phone") {
      return <SVGPhone />;
    } else if (icon === "upload") {
      return <SVGUpload />;
    }
  }
  return (
    <div
      key={key}
      style={{ height: inline ? "48px" : "62px", backgroundColor: {bgColor} }}
      className={disabled ? "primary_button-container_disabled" : "primary_button-container"}
      onClick={disabled ? null : onClick}
    >
      {
        disabled ?
          <div style={{ marginTop: '0px' }}>
            <PulseLoader color="#f5735a" size={8}
            />
          </div> :
          <div className="primary_button-text_container">
            <Body1 text={lable} weight="semitBold" color="white" />
          </div>
      }

      <div style={{ height: "24px", marginLeft: "8px" }}>{getIcon(icon)}</div>
    </div>
  );
};

export default PrimaryButton;
