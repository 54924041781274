import { ReactComponent as SVGPad } from "../../svg/pad.svg";
import { ReactComponent as SVGPhone } from "../../svg/phone-call.svg";
import { ReactComponent as SVGBook } from "../../svg/book-open.svg";
import { ReactComponent as SVGUpload } from "../../svg/upload.svg";
import { ReactComponent as SVGPlus } from "../../svg/plus-circle.svg";

import { Body2 } from "../../text/index";

import "./InlineButton.css";

const InlineButton = ({ icon, lable, bgScheme, key, onClick, inline }) => {
  function getIcon(icon) {
    if (icon === "book") {
      return <SVGBook />;
    } else if (icon === "pad") {
      return <SVGPad />;
    } else if (icon === "phone") {
      return <SVGPhone />;
    } else if (icon === "upload") {
      return <SVGUpload />;
    } else if (icon === "plus") {
      return <SVGPlus />;
    }
  }
  return (
    <div
      key={key}
      style={{}}
      className="inline_button-container"
      onClick={onClick}
    >
      {icon && (
        <div style={{ height: "18px", marginRight: "8px" }}>
          {getIcon(icon)}
        </div>
      )}
      <div className="inline_button-text_container">
        <Body2 text={lable} weight="lightBold" color="magentaDye" />
      </div>
    </div>
  );
};

export default InlineButton;
