import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../../config";
import { setExpert } from "../../../stores/actions/expert";
import { useSelector, useDispatch } from "react-redux";
import { expertDataSelector } from "../../../stores/selectors/expert";
import { Heading3, Body2 } from "../../elements/text";
import { HomeOutlined, UsergroupAddOutlined, FileTextOutlined, CustomerServiceOutlined, SolutionOutlined } from '@ant-design/icons';
import UviLogo from "../../common/uviLogo";
import { ReactComponent as SVGDefault } from "../../elements/svg/default-profile.svg";
import HeaderWithProfile from "../../../components/headerWithProfile";
import ProfileCompletion from "../../common/profileCompletionComp";
import './Navbar.css'
import { Layout, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';
const { Sider } = Layout;

const Navbar = ({ title, defaultIndex }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [expertData, setExpertData] = useState({})
    const _expertData = useSelector((state) => expertDataSelector(state));

    useEffect(() => {
        const getData = async () => {
            const resp = await axios.get(ROOT_URL + "/profile", {
                withCredentials: true,
                credentials: "include",
                params: null,
            });
            if (resp && resp.data && resp.data.responseData)
                dispatch(setExpert(resp.data.responseData[0]));
        };
        getData();
    }, []);

    useEffect(() => {
        if (_expertData && _expertData.name) {
            setExpertData(_expertData)
        }
    }, [_expertData])

    let Links = [
        { name: "All Prescription", link: "/" },
        { name: "My Consults", link: "/" },
    ];



    const navList = [["Home", HomeOutlined, '/'], ["All Patient", UsergroupAddOutlined, '/patients'], ["Prescriptions", FileTextOutlined, '/prescriptions'], ["Successful Referrals", SolutionOutlined, '/successfulReferrals'], ["Support", CustomerServiceOutlined, '/support']]
    return (
        <div>
            <Sider
                breakpoint="lg"
                className="sider"
                width={250}
                style={{ backgroundColor: "#fff", borderColor: "rgb(39, 80, 112)", height: "100vh" }}
            >
                <div style={{ marginTop: 10, marginBottom: 18 }}>
                    <div style={{ marginLeft: 20 }}>
                        <UviLogo />
                    </div>
                </div>
                <div className='sidebar-profile'>

                    <ProfileCompletion expertData={expertData} />

                    {/* <div className='sidebar-profile-text'>{"Dr. "+expertData.name}</div> */}
                </div>
<div style={{paddingTop:"8px"}}></div>
                <Menu
                    mode="inline"
                    // defaultSelectedKeys={navList.filter((item, index) => item[2] === '/'? `${index}`: '')}
                    // selectedKeys={'/patients'}
                    defaultSelectedKeys={[`${defaultIndex}`]}
                    onClick={(item) => {
                        history.push(navList[item.key - 1][2])
                        console.log(item)
                    }}
                    items={navList.map(
                        (item, index) => ({
                            key: String(index + 1),
                            label: item[0],
                            icon: React.createElement(item[1]),
                        }),
                    )}
                />
            </Sider>
        </div>
    )
}

export default Navbar