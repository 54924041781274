const EXPERT = '[EXPERT]';

export const EXPERT_GET = `${EXPERT} GET`;
export const EXPERT_SET = `${EXPERT} SET`;

export const getExpert = (payload) => ({
    type: EXPERT_GET,
    payload,
});

export const setExpert = (payload) => ({
    type: EXPERT_SET,
    payload,
});

