import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import { apiMiddleware } from "./middlewares/core/api";
import { loginMiddleware } from "./middlewares/feature/login";
import { patientMiddleware } from "./middlewares/feature/patient";
import { prescriptionMiddleware } from "./middlewares/feature/prescription";
import { successfulReferralMiddleware } from "./middlewares/feature/successfulReferral";
import { homeMiddleware } from "./middlewares/feature/home";

import { authReducer } from "./reducers/login/";
import { expertReducer } from "./reducers/expert/";
import { patientReducer } from "./reducers/patient/";
import { prescriptionReducer } from "./reducers/prescription/";
import { successfulReferralReducer } from "./reducers/successfulReferral/";
import { homeReducer } from "./reducers/home/";


const coreMiddleware = [apiMiddleware];
const featureMiddleware = [loginMiddleware, prescriptionMiddleware, patientMiddleware, successfulReferralMiddleware, homeMiddleware];

let enhance;
if (process.env.NODE_ENV === "development") {
  enhance = compose(
    applyMiddleware(...featureMiddleware, ...coreMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  enhance = compose(applyMiddleware(...featureMiddleware, ...coreMiddleware));
}

const rootReducer = combineReducers({
  authReducer,
  expertReducer,
  patientReducer,
  prescriptionReducer,
  successfulReferralReducer,
  homeReducer,
});

const store = createStore(rootReducer, enhance);

export default store;

// import { legacy_createStore as createStore, applyMiddleware } from "redux";
// import creatSagaMiddleware from "redux-saga";
// import { persistStore, persistReducer } from "redux-persist";

// import rootReducer from "./main.reducer";
// import rootSaga from "./main.saga";
// import storage from "redux-persist/lib/storage";

// const persistConfig = {
//   key: "root",
//   storage: storage,
//   blacklist: [
//     "forgorPassword",
//     "publicChat",
//     "settings",
//     "camsitesSettings",
//     "appStatus",
//     "stream",
//     "shows",
//   ],
// };
// //
// const pReducer = persistReducer(persistConfig, rootReducer);

// const sagaMiddleware = creatSagaMiddleware();
// const store = createStore(pReducer, undefined, applyMiddleware(sagaMiddleware));
// sagaMiddleware.run(rootSaga);

// export const persistor = persistStore(store);
// export default store;
