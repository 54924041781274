import {PATIENTS_SET, PATIENT_SET} from '../../actions/patient';

const initState = []
export const patientReducer = (state=initState,action) =>{
    switch(action.type){
        case PATIENTS_SET:
            return {...state,patientsList:action.payload}
        case PATIENT_SET:
            return {...state,patientDetails:action.payload}
        default: return state
    }
}
