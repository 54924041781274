import { apiRequest, API_SUCCESS, API_ERROR } from "../../../actions/api";
import Alert from "sweetalert2";
import { STATS, STATS_GET, setStats} from "../../../actions/home";
import { ROOT_URL } from "../../../../config/";

export const homeMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case STATS_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/referralStats`,
            method: "get",
            feature: STATS,
          })
        );
        break;

      case `${STATS} ${API_SUCCESS}`:
        dispatch(setStats(action.payload));
        break;

      case `${STATS} ${API_ERROR}`:
        Alert.fire({
          icon: "error",
          title: "Error",
          showConfirmButton: false,
          timer: 1500,
        });
        break;
      default:
        break;
    }
  };
