import { apiRequest, API_SUCCESS, API_ERROR } from "../../../actions/api";
import Alert from "sweetalert2";
import {
  OTP,
  LOGIN,
  LOGIN_CHECK,
  OTP_GET,
  OTP_VERIFY,
  setAuthentication,
  setOtp,
} from "../../../actions/login/";
import { ROOT_URL } from "../../../../config/";
import { setExpert } from "../../../actions/expert";

export const loginMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case OTP_GET:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/generateOtp`,
            method: "post",
            feature: OTP,
          })
        );
        break;
      case OTP_VERIFY:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/verifyOtp`,
            method: "post",
            feature: OTP,
          })
        );
        break;
      case LOGIN_CHECK:
        dispatch(
          apiRequest({
            body: action.payload,
            config: {},
            url: `${ROOT_URL}/loginCheck`,
            method: "get",
            feature: LOGIN,
          })
        );
        break;
      case `${LOGIN} ${API_SUCCESS}`:
        dispatch(setAuthentication({ isAuthenticated: true }));
        dispatch(setExpert(action.payload));
        break;
      case `${LOGIN} ${API_ERROR}`:
        dispatch(setAuthentication({ isAuthenticated: false }));
        break;
      case `${OTP} ${API_SUCCESS}`:
        if (action.message === "user auth successful") {
          dispatch(setAuthentication({ isAuthenticated: true }));
          dispatch(setExpert(action.payload));
        } else {
          if(action.payload === false){
            Alert.fire({
              position: "top-end",
              icon: "error",
              title: "Could not generate OTP, Please request registration if not registered yet.",
              showConfirmButton: false,
              timer: 2500,
            });
          }
          dispatch(setOtp(action.payload));
        }
        break;
      case `${OTP} ${API_ERROR}`:
        Alert.fire({
          position: "top-end",
          icon: "error",
          title: action.message,
          showConfirmButton: false,
          timer: 1500,
        });
        break;

      default:
        break;
    }
  };
