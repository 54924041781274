import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { ReactComponent as SVGPrescriptionPad } from "../../components/elements/svg/prescriptionpad.svg";
import { ReactComponent as SVGUviRefer } from "../../components/elements/svg/refer.svg";

import "./Home.css";

import axios from "axios";
import { ROOT_URL } from "../../config";
import { setExpert } from "../../stores/actions/expert";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { expertDataSelector } from "../../stores/selectors/expert";
import { getStats } from "../../stores/actions/home";
import { statsSelector } from "../../stores/selectors/home";
import HeaderWithProfile from "../../components/headerWithProfile";
import LargeButtonWithIcon from "../../components/elements/buttons/largeButtonWithIcon";
import SupportSection from "../../components/supportSection";
import Navbar from "../../components/common/navbar/Navbar";
import { Heading2, Heading3, Heading4, Heading5 } from "../../components/elements/text";
import { ReactComponent as CreatePrescription } from "../../components/elements/svg/createPrescription.svg";
import { Layout, Card, Row, Col } from 'antd';
const { Meta } = Card;
const { Header, Content, Footer, Sider } = Layout;


const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [expertData, setExpertData] = useState({})
  const _expertData = useSelector((state) => expertDataSelector(state));
  const [reRender, setReRender] = useState(true);
  const [onboarded, setOnboarded] = useState(null);
  const [verified, setVerified] = useState(null);

  useEffect(() => {
    dispatch(getStats());
    const getData = async () => {
      const resp = await axios.get(ROOT_URL + "/profile", {
        withCredentials: true,
        credentials: "include",
        params: null,
      });
      if (resp && resp.data && resp.data.responseData)
        dispatch(setExpert(resp.data.responseData[0]));
    };
    getData();
  }, []);

  const stats = useSelector((state) => statsSelector(state));
  console.log(stats);

  useEffect(() => {
    if (_expertData && _expertData.name) {
      setExpertData(_expertData)
    }
    setReRender(!reRender)
    setOnboarded(_expertData.onboarded)
    setVerified(_expertData.verified)
  }, [_expertData])

  useEffect(() => {
    if (verified === 0) {
      history.push("/register");
    } else if (onboarded === 0) {
      history.push("/profile");
    }
  }, [onboarded, verified])

  const successAlert = async (message, action) => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 2500,
    });
    const resp = await axios.post(
      ROOT_URL + "/alerts",
      {
        name: expertData.name,
        mobileNumber: expertData.calling_number,
        alertContext: action,
      },
      {
        withCredentials: true,
        credentials: "include",
        params: null,
      }
    );
  };
  return (
    <Layout style={{ backgroundColor: "#fff" }}>
      <Navbar title={"Welcome, Dr. " + expertData.name} defaultIndex={1} />

      <Layout>
        <Content
        >
          <div className="dashboard-container">


            <HeaderWithProfile isHome={true} title={" Dr. " + expertData.name} />
            <div className="dashboard-content">
              <div className="quick-link-container">
                <Heading3 weight="bold" color="oxfordBlue" text="Quick Links" />
                <div className="quick-link-cards">

                  <Card
                    className="quickLinks-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #275070", cursor: "pointer" }}
                    onClick={() => history.push("/newPrescription")}
                  >
                    <div className="home-quickLinks">
                      <img src="/images/Create_Prescription.png" alt="Create Prescription" />
                    </div>
                    <div className="quicklinks-card-text" style={{ backgroundColor: "#275070" }}>
                      <span style={{ color: "white" }}>Create a Prescription</span>
                    </div>

                  </Card>
                  <Card
                    className="quickLinks-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #DC4A9A", cursor: "pointer" }}
                    onClick={() => history.push("/referral")}
                  >
                    <div className="home-quickLinks">
                      <img src="/images/Refer_Patient.png" alt="Refer Patient" />
                    </div>
                    <div className="quicklinks-card-text" style={{ backgroundColor: "#DC4A9A" }}>
                      <span style={{ color: "white" }}>Refer a Patient</span>
                    </div>
                  </Card>
                </div>

              </div>
              <div className="overview-container">
                <Heading3 weight="bold" color="oxfordBlue" text="Overview" />
                <div className="overview-cards">
                  <Card
                    className="overview-card"
                    width={100}
                    style={{ borderRadius: "10px", backgroundColor: "rgba(252, 240, 255, 0.5)" }}
                  >
                    <div className="overview-card-text-container" >
                      <div className="overview-card-text">Patients Referred</div>
                      <div class="tooltip">
                        <img src="/images/info-icon.svg" alt="info-icon" />
                        <span class="tooltiptext">Number of Referred and Prescribed patients</span>
                      </div>
                    </div>
                    <div className="home-overview">
                      <img className="home-overview-icon" src="/images/Referral_Amount.svg" alt="Patient" />
                      <span className="overview-card-number" style={{ color: "#9073A2" }}>{stats ? stats.patient_referred : 0}</span>
                    </div>
                    {/* <div className="overview-card-text">
                      <span >Patient Referred</span>
                    </div> */}

                  </Card>
                  <Card
                    className="overview-card"
                    width={100}
                    style={{ borderRadius: "10px", backgroundColor: 'rgba(11, 190, 120, 0.03)' }}
                  >
                    <div className="overview-card-text-container" >
                      <div className="overview-card-text">Successful Referral</div>
                      <div class="tooltip">
                        <img src="/images/info-icon.svg" alt="info-icon" />
                        <span class="tooltiptext">Number of purchases from your patients</span>
                      </div>
                    </div>
                    <div className="home-overview">
                      <img className="home-overview-icon" src="/images/Referral.svg" alt="Refer Patient" />
                      <span className="overview-card-number" style={{ color: "#0BBE78" }}>{stats ? stats.successful_referrals : 0}</span>
                    </div>
                   
                  </Card>
                  <Card
                    className="overview-card"
                    width={100}
                    style={{ borderRadius: "10px", backgroundColor: "#FCF1F7" }}
                  >
                    <div className="overview-card-text-container" >
                      <div className="overview-card-text">Referral Amount</div>
                      <div class="tooltip">
                        <img src="/images/info-icon.svg" alt="info-icon" />
                        <span class="tooltiptextMobileOnly" >Total commission earned</span>
                      </div>
                    </div>
                    <div className="home-overview">
                      <img className="home-overview-icon" src="/images/Patients.svg" alt="Refer Patient" />
                      <span className="overview-card-number" style={{ color: "#DC4A9A" }}>{stats ? stats.referral_amount : 0}</span>
                    </div>
                    
                  </Card>

                </div>
              </div>

            </div>









            {/* <div className="dashboard-sidebar">
              <Row style={{ marginBottom: "20px" }} className="overview-desktop">
                <Col xs={24} sm={24} lg={12}>
                  <Heading3 weight="bold" color="oxfordBlue" text="Quick Links" />
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Heading3 weight="bold" color="oxfordBlue" text="Overview" />
                </Col>
              </Row>


              <Row style={{ justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px", margin: "auto" }}>
                <Col xs={24} sm={24} className="overview-mobile">
                  <Heading3 weight="bold" color="oxfordBlue" text="Quick Links" />
                </Col>
                <Col xs={12} sm={12} lg={4}>
                  <Card
                    className="quickLinks-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #275070", cursor: "pointer" }}
                    onClick={() => history.push("/newPrescription")}
                  >
                    <div className="home-quickLinks">
                      <img src="/images/Create_Prescription.png" alt="Create Prescription" />
                    </div>
                    <div className="quicklinks-card-text" style={{ backgroundColor: "#275070" }}>
                      <span style={{ color: "white" }}>Create a Prescription</span>
                    </div>
                  </Card>
                </Col>

                <Col xs={12} sm={12} lg={4}>
                  <Card
                    className="quickLinks-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #DC4A9A", cursor: "pointer" }}
                    onClick={() => history.push("/referral")}
                  >
                    <div className="home-quickLinks">
                      <img src="/images/Refer_Patient.png" alt="Refer Patient" />
                    </div>
                    <div className="quicklinks-card-text" style={{ backgroundColor: "#DC4A9A" }}>
                      <span style={{ color: "white" }}>Refer a Patient</span>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} sm={24} className="overview-mobile">
                  <Heading3 weight="bold" color="oxfordBlue" text="Overview" />
                </Col>

                <Col xs={8} sm={8} lg={3}>
                  <Card
                    className="overview-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #E5E5E5", backgroundColor: "rgba(252, 240, 255, 0.5)" }}
                  >
                    <div className="home-overview">
                      <img className="home-overview-icon" src="/images/Referral_Amount.svg" alt="Patient" />
                      <span className="overview-card-number" style={{ color: "#9073A2" }}>32</span>
                    </div>
                    <div className="overview-card-text">
                      <span >Patient Referred</span>
                    </div>
                  </Card>
                </Col>
                <Col xs={8} sm={8} lg={3}>
                  <Card
                    className="overview-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #E5E5E5", backgroundColor: 'rgba(11, 190, 120, 0.03)' }}
                  >
                    <div className="home-overview">
                      <img className="home-overview-icon" src="/images/Referral.svg" alt="Refer Patient" />
                      <span className="overview-card-number" style={{ color: "#0BBE78" }}>12</span>
                    </div>
                    <div className="overview-card-text">
                      <span>Successful Referral</span>
                    </div>
                  </Card>
                </Col>
                <Col xs={8} sm={8} lg={3}>
                  <Card
                    className="overview-card"
                    width={100}
                    style={{ borderRadius: "10px", border: "1px solid #E5E5E5", backgroundColor: "#FCF1F7" }}
                  >
                    <div className="home-overview">
                      <img className="home-overview-icon" src="/images/Patients.svg" alt="Refer Patient" />
                      <span className="overview-card-number" style={{ color: "#DC4A9A" }}>11,700</span>
                    </div>
                    <div className="overview-card-text">
                      <span >Referral Amount</span>
                    </div>
                  </Card>
                </Col>
              </Row>
              Support section
              <SupportSection
                successAlert={successAlert}
                temp={true}
                onClick={() => {
                  history.push("/view-prescriptions");
                }}
              />
              <SupportSection successAlert={successAlert} />
            </div> */}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
