export const OTP = `[OTP]`
export const AUTH = `[AUTH]`
export const LOGIN = `[LOGIN]`

export const OTP_GET = `${OTP} GET`;
export const OTP_SET = `${OTP} SET`
export const OTP_VERIFY = `${OTP} VERIFY`;
export const AUTH_SET = `${AUTH} SET`
export const LOGIN_CHECK = `${LOGIN} CHECK`


export const getOtp = (data) =>({
    type: OTP_GET,
    payload:data
})

export const setOtp = (data) =>({
    type:OTP_SET,
    payload:data
})

export const verifyOtp = (data) => ({
    type: OTP_VERIFY,
    payload: data
})

export const setAuthentication = (data) =>({
    type: AUTH_SET,
    payload:data
})

export const loginCheck = () =>({
    type:LOGIN_CHECK,
    payload:{}
})